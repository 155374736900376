import { AuthState } from './types';

export const getState = (): AuthState => ({
  session: undefined,
  user: undefined,
  currentUserLanguage: undefined,
  simulation: false,
  supervisorUser: undefined,
});

export default Object.assign(getState(), { getState });
