import createExchange, { ExchangeInterface } from './Exchange';

export interface SettingsInterface {
  id: string;
  userId?: string;
  name: string;
  status: string;
  strategy: string;
  strategyOptions: any;
  notificationInterval: number;
  notificationSmsSnabled: boolean;
  notificationSmsPhones: string[];
  createdAt: Date;
  updatedAt: Date;
  exchanges?: ExchangeInterface[],
}

export const createSettings = (data: any): SettingsInterface => ({
  id: data.id || undefined,
  userId: data.userId || undefined,
  name: data.name || undefined,
  status: data.status || undefined,
  strategy: data.strategy || undefined,
  strategyOptions: data.strategyOptions || {},
  notificationInterval: data.notificationInterval || 0,
  notificationSmsSnabled: data.notificationSmsSnabled || false,
  notificationSmsPhones: data.notificationSmsPhones || [],
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.createdAt),
  exchanges: (data.exchanges || []).map((e: any) => createExchange(e)),
});

export default createSettings;
