<script>
// TODO Implementation necessary for waiting vuetify 2.5
import VTextField from 'vuetify/es5/components/VTextField';

export default VTextField.extend({
  name: 'TextField',
  computed: {
    showLabel () {
      return this.hasLabel && (!this.isSingle || (!this.isLabelActive && !this.placeholder));
    },

    labelValue () {
      return !this.isSingle && Boolean(this.isFocused || this.isLabelActive || this.placeholder);
    },
  },
  methods: {
    genInput () {
      const listeners = Object.assign({}, this.listeners$);
      delete listeners.change; // Change should not be bound externally

      return this.$createElement('input', {
        style: {},
        domProps: {
          value: (this.type === 'number' && Object.is(this.lazyValue, -0)) ? '-0' : this.lazyValue,
        },
        attrs: {
          ...this.attrs$,
          autofocus: this.autofocus,
          disabled: this.isDisabled,
          id: this.computedId,
          placeholder: this.placeholder,
          readonly: this.isReadonly,
          type: this.type,
        },
        on: Object.assign(listeners, {
          blur: this.onBlur,
          input: this.onInput,
          focus: this.onFocus,
          keydown: this.onKeyDown,
        }),
        ref: 'input',
        directives: [{
          name: 'resize',
          modifiers: { quiet: true },
          value: this.onResize,
        }],
      });
    },
  },
});

</script>
