import { Currency } from '@/entities/Currency';
import { toString } from '@/helpers/decimalHelper';

export const currencyFilter = (value: string | number, currency?: string | Currency, precision?: number) => {
  return toString(value, currency, precision);
};

export default {
  currencyFilter,
};
