





























































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgResultForCurrency from '@/components/Reports/ResultForPeriod/ResultForCurrency.vue';
import FgResultForSettings from '@/components/Reports/ResultForPeriod/ResultForSettings.vue';
import FgVolumeForCurrency from '@/components/Reports/ResultForPeriod/VolumeForCurrency.vue';
import FgVolumeForExchange from '@/components/Reports/ResultForPeriod/VolumeForExchange.vue';
import getResultByPeriod from '@/services/Reports/getResultForPeriod';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgResultForCurrency, FgResultForSettings, FgVolumeForCurrency, FgVolumeForExchange },
})
export default class ResultForPeriod extends Vue {
  protected loading = false;
  protected breadcrumbs: any;

  protected userId = '';

  protected startDate = '';
  protected showStartDate = false;

  protected endDate = '';
  protected showEndDate = false;

  protected resultData: any = false;

  @AuthStore.Getter('isLogged') isLogged!: boolean;
  @AuthStore.Getter('isInternal') isInternal!: boolean;

  get dateRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => {
        const date = new Date(value);
        return date instanceof Date || 'Invalid Date';
      },
    ];
  }

  public beforeMount () {
    if (!this.isLogged) {
      this.$router.push({ name: 'Login' });
    }
    this.breadcrumbs = [
      {
        text: this.$t('menu.dashboard'),
        to: { name: 'Home' },
        exact: true,
        disabled: false,
      },
      {
        text: this.$t('menu.reports.title'),
        exact: true,
        disabled: true,
      },
      {
        text: this.$t('menu.reports.results'),
        to: { name: 'ResultForPeriod' },
        exact: true,
        disabled: false,
      },
    ];
  }

  protected allowedEndDates (value: string) {
    return new Date(value) >= new Date(this.startDate);
  }

  protected changeLoading () {
    this.loading = false;
  }

  protected print () {
    window.print();
  }

  protected async generateReport () {
    if (!this.startDate || !this.endDate) {
      this.$notify.error('Invalid Params', this.$t('actions.error'));
      return;
    }
    try {
      this.loading = true;
      this.resultData = await getResultByPeriod({ start: this.startDate, end: this.endDate, userId: this.userId });
      this.loading = false;
    } catch (e) {
      this.loading = false;
    }
  }
}
