import createUser, { UserInterface } from '@/entities/User';
import http, { captureException } from '@/services/http';
import FilteredOptionsInterface, { makeQueryParamsForRequest } from '@/entities/FilteredOptions';
import { createPagedMeta } from '../../entities/PagedMeta';
import PagedResource from '../../interfaces/PagedResource';

export const getUsers = async (filteredOptions: FilteredOptionsInterface): Promise<PagedResource<UserInterface>> => {
  try {
    const params = makeQueryParamsForRequest(filteredOptions);
    const response = await http().get('/api/v1/users', { params });
    if (!response?.data?.data || !Array.isArray(response?.data?.data)) return { data: [], meta: createPagedMeta() };
    return {
      data: response.data.data.map((e: any) => createUser(e)),
      meta: response?.data?.meta || createPagedMeta(),
    };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { data: [], meta: createPagedMeta() };
  }
};

export default getUsers;
