



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ExecutionInterface } from '@/entities/Execution';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgOrdersList from '@/components/Orders/OrdersList.vue';
import FgStatus from '@/components/Dashboard/Status.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { FgWidget, FgStatus, FgOrdersList },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionDetails extends Vue {
  @Prop({ type: Object, required: true }) execution!: ExecutionInterface;

  get showResultPercent (): boolean {
    if (this.execution.executedResult === 0) return false;
    if (!['FINISHED', 'PARTIAL'].includes(this.execution.status || '')) return false;

    if (!this.execution.orders[0] || this.execution.executedResultCurrency !== this.execution.orders[0].currencyPrimary) return false;
    return true;
  }

  get executedResultPercent (): number {
    const totalOrders = this.execution.orders.reduce((ac, o) => {
      ac = ac + (o.value + (o.fee * o.feePrice));
      return ac;
    }, 0);
    return (this.execution.executedResult * 100) / totalOrders;
  }

  protected close () {
    this.$emit('close');
  }
}

