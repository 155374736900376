import createUser, { UserInterface } from './User';

export enum EventLogOrigin {
  UNKNOWN = 'unknown',
  AUTH = 'auth',
  EXCHANGES = 'exchanges',
  NOTIFICATIONS = 'notifications',
  SETTINGS = 'settings',
  USERS = 'users',
  WEBSOCKETS = 'websockets',
}

export interface EventLogInterface {
  id: string;
  level: string;
  userId?: string;
  origin: EventLogOrigin;
  event: string;
  message: string;
  data?: any;
  user?: UserInterface;
  createdAt: Date
}

export const createEventLog = (data: any): EventLogInterface => ({
  id: data.id || undefined,
  level: data.level || 'log',
  userId: data.userId || undefined,
  origin: data.origin || EventLogOrigin.UNKNOWN,
  event: data.event || '',
  message: data.message || '',
  data: data.data || {},
  user: data.user ? createUser(data.user) : undefined,
  createdAt: new Date(data.createdAt),
});

export default createEventLog;
