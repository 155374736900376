const appData = JSON.parse(unescape(process.env.APP_DATA || '%7B%7D'));

const config = {
  appUrl: process.env.APP_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL,
  name: process.env.VUE_APP_NAME,
  version: appData.version || '0',
  reloadInterval: 60000,
  env: process.env.NODE_ENV,
};

export default config;
