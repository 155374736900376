












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgUsersListFilters from '@/components/Users/UsersListFilters.vue';
import FgChangeStatus from '@/components/Core/ChangeStatus.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { ExecutionInterface } from '@/entities/Execution';
import { OrderInterface } from '@/entities/Order';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import PagedResource from '@/interfaces/PagedResource';
import { createOptionsPage } from '@/entities/OptionsPaged';
import { FilterInterface } from '@/entities/FilteredOptions';
import { namespace } from 'vuex-class';
import { UserInterface } from '../../entities/User';

const AuthStore = namespace('Auth');

@Component({
  components: { FgStatus, FgResumeTextField, FgUsersListFilters, FgChangeStatus },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionsDataTable extends Vue {
  @Prop({ type: Object, required: true }) resource!: PagedResource<ExecutionInterface>;
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean;
  @Prop({ type: Array, required: false, default: () => [] }) filters!: FilterInterface[];
  @Prop({ type: Boolean, required: false, default: true }) showFilters!: boolean[];

  @AuthStore.Getter('isInternal') isInternal?: boolean;
  @AuthStore.Getter('currentUser') currentUser?: boolean;

  protected headers: any = [];
  protected options = createOptionsPage(this.resource?.meta);

  get users () {
    return this.resource?.data || [];
  }

  get isMobile () {
    return this.$vuetify.breakpoint.xs;
  }

  get showFooter () {
    return !!this.resource?.meta;
  }

  get totalItems () {
    return this.resource?.meta?.total || this.resource?.data?.length || 0;
  }

  get footerProps () {
    return {
      itemsPerPageOptions: [5, 10, 30, 100],
      showFirstLastPage: true,
      itemsPerPageText: this.$t('dataTable.rowsPerPage'),
    };
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('users.headers.id').toString(),
        align: 'start',
        sortable: false,
        filterable: true,
        value: 'id',
        width: '60px',
      },
      {
        text: this.$t('users.headers.createdAt').toString(),
        align: 'start',
        sortable: false,
        value: 'createdAt',
        width: '180px',
      },
      {
        text: this.$t('users.headers.name').toString(),
        align: 'start',
        sortable: false,
        value: 'name',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('users.headers.email').toString(),
        align: 'start',
        sortable: false,
        value: 'email',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('users.headers.roles').toString(),
        align: 'start',
        sortable: false,
        value: 'roles',
      },
      {
        text: this.$t('users.headers.isEnabled2fa').toString(),
        align: 'start',
        sortable: false,
        value: 'isEnabled2fa',
      },
      {
        text: this.$t('users.headers.status').toString(),
        align: 'center',
        sortable: false,
        value: 'status',
        width: '80px',
      },
    ];
  }

  protected getColorByItem (item: ExecutionInterface) {
    if (item.executedResult > 0) return 'success';
    if (item.executedResult < 0) return 'error';
    return 'grey';
  }

  protected getIconByItem (item: ExecutionInterface) {
    if (item.executedResult > 0) return '$up';
    if (item.executedResult < 0) return '$down';
    return 'fas fa-times';
  }

  protected showResultPercent (item: ExecutionInterface): boolean {
    if (item.executedResult === 0) return false;
    if (!['FINISHED', 'PARTIAL'].includes(item.status || '')) return false;

    if (!item.orders[0] || item.executedResultCurrency !== item.orders[0].currencyPrimary) return false;
    return true;
  }

  protected getExecutedResultPercent (item: ExecutionInterface) {
    const totalOrders = item.orders.reduce((ac, o) => {
      ac = ac + (o.value + (o.fee * o.feePrice));
      return ac;
    }, 0);
    return (item.executedResult * 100) / totalOrders;
  }

  protected getBuyOrders (orders: OrderInterface[]) {
    return orders?.filter(o => o.operation === 'BUY') || [];
  }

  protected getSellOrders (orders: OrderInterface[]) {
    return orders?.filter(o => o.operation === 'SELL') || [];
  }

  protected rowClicked (_: any, row: any) {
    this.$emit('dblclick', row);
  }

  protected changeFilters (filters: FilterInterface[]) {
    this.$emit('changeFilters', filters);
  }

  protected changeStatus (user: UserInterface) {
    if (this.loading || !user) return false;

    this.$emit('changeStatus', user);
  }

  protected mounted () {
    this.options = createOptionsPage(this.resource?.meta);
  }

  @Watch('options')
  protected async changeOptions (value: any) {
    this.$emit('changeOptions', value);
  }
}

