import { CurrentStatistics } from '@/store/modules/Trade/types';
import http, { captureException } from '@/services/http';

export const getCurrentStatistics = async (params: any = {}): Promise<{data: CurrentStatistics}> => {
  try {
    const response = await http().get('/api/v1/executions/current-statistics', { params });
    if (!response?.data?.data || typeof response?.data?.data !== 'object') return { data: {} };

    const result: CurrentStatistics = {};
    const data = response.data.data;
    for (const currency of Object.keys(data)) {
      if (!currency || !data[currency]) continue;
      result[currency] = {
        dayCount: parseInt(data[currency].dayCount, 10),
        dayTotal: Number(data[currency].dayTotal),
        weekCount: parseInt(data[currency].weekCount, 10),
        weekTotal: Number(data[currency].weekTotal),
        monthCount: parseInt(data[currency].monthCount, 10),
        monthTotal: Number(data[currency].monthTotal),
      };
    }
    return {
      data: result,
    };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { data: {} };
  }
};

export default getCurrentStatistics;
