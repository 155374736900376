import http, { captureException } from '@/services/http';

export const login = async (email: string, password: string) => {
  try {
    const response = await http().post('/api/v1/auth/login', { email, password });
    const session = response?.data?.data;
    return { session, error: null };
  } catch (e) {
    captureException(e);
    const error = {
      translate: false,
      message: e?.response?.data?.message || e.message,
    };
    if (e?.response?.status === 429) {
      error.translate = true;
      error.message = 'login.tooManyRequests';
    }
    return { session: null, error };
  }
};

export default login;
