import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authorizer from '@/middlewares/authorizer';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Settings from '@/views/Settings.vue';
import Exchanges from '@/views/Exchanges.vue';
import Executions from '@/views/Executions.vue';
import Users from '@/views/Users.vue';
import ExecutionRequests from '@/views/ExecutionRequests.vue';
import EventLogs from '@/views/EventLogs.vue';
import ResultForPeriod from '@/views/Reports/ResultForPeriod.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authorizer,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: authorizer,
  },
  {
    path: '/exchanges',
    name: 'Exchanges',
    component: Exchanges,
    beforeEnter: authorizer,
  },
  {
    path: '/executions',
    name: 'Executions',
    component: Executions,
    beforeEnter: authorizer,
  },
  {
    path: '/execution-requests',
    name: 'ExecutionRequests',
    component: ExecutionRequests,
    beforeEnter: authorizer,
  },
  {
    path: '/reports/result-for-period',
    name: 'ResultForPeriod',
    component: ResultForPeriod,
    beforeEnter: authorizer,
  },

  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: authorizer,
  },

  {
    path: '/event-logs',
    name: 'EventLogs',
    component: EventLogs,
    beforeEnter: authorizer,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
