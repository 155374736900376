export const timeFilter = (date: string | Date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return date.toLocaleTimeString();
};

export const dateTimeFilter = (date: string | Date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return date.toLocaleString('en');
};

export default {
  timeFilter,
  dateTimeFilter,
};
