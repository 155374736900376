export interface UserInterface {
  id: string;
  name: string;
  phone: string;
  email: string;
  language: string;
  isEnabled2fa: boolean;
  roles: string[];
  permitions: string[];
  features: string[];
  telegramClientIds: string[];
  webhookExecutionRequest?: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
}

export const createUser = (data: any) => ({
  id: data.id || undefined,
  name: data.name || '',
  phone: data.phone || '',
  status: data.status || 'DISABLED',
  email: data.email || '',
  language: data.language || 'en',
  isEnabled2fa: data.isEnabled2fa || false,
  roles: data.roles || [],
  permitions: data.permitions || [],
  features: data.features || [],
  telegramClientIds: data.telegramClientIds || [],
  webhookExecutionRequest: data.webhookExecutionRequest || '',
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.createdAt),
});

export default createUser;
