import { ActionTree } from 'vuex';
import createTicker from '@/entities/Ticker';
import getBestTickers from '@/helpers/bestBuySellTicker';
import getExchangeDrivers from '@/services/Exchanges/getExchangeDrivers';
import getCurrentStatistics from '@/services/Executions/getCurrentStatistics';
import getExecutions from '@/services/Executions/getExecutions';
import { TradeState } from './types';
import FilteredOptionsInterface from '@/entities/FilteredOptions';
import { createOptionsPage } from '@/entities/OptionsPaged';

const actions: ActionTree<TradeState, TradeState> = {
  addExecution ({ commit }, payload) {
    commit('ADD_EXECUTION', payload);
  },

  updateExecution ({ commit }, payload) {
    commit('UPDATE_EXECUTION', payload);
  },

  addExecutionRequest ({ commit }, payload) {
    commit('ADD_EXECUTION_REQUEST', payload);
  },

  updateExecutionRequest ({ commit }, payload) {
    commit('UPDATE_EXECUTION_REQUEST', payload);
  },

  clearExecutions ({ commit }) {
    commit('SET_EXECUTIONS', []);
  },

  async updateLastExecutions ({ commit }) {
    const options: FilteredOptionsInterface = {
      populate: ['orders', 'orders.exchange'],
      options: createOptionsPage(),
      filters: [],
    };
    const executions = await getExecutions(options);
    if (executions?.data && executions?.data.length > 0) {
      commit('SET_EXECUTIONS', executions?.data);
    }
  },

  async updateExecutionsCurrentStatistics ({ commit }) {
    const statistics = await getCurrentStatistics();
    commit('SET_EXECUTIONS_STATISTICS', statistics.data || {});
  },

  addExecutionCurrentStatisticsValue ({ commit }, payload) {
    if (payload?.status === 'FINISHED' && payload?.executedResult > 0) {
      commit('ADD_EXECUTION_CURRENT_STATISTICS', payload);
    }
  },

  addOpportunity ({ commit }, payload) {
    commit('ADD_OPPORTUNITY', payload);
  },

  updateOpportunity ({ commit }, payload) {
    commit('UPDATE_OPPORTUNITY', payload);
  },

  clearOpportunities ({ commit }) {
    commit('SET_OPPORTUNITIES', []);
  },

  addEvent ({ commit }, payload) {
    commit('ADD_EVENT', payload);
  },

  toggleRegisterEvents ({ commit }, payload) {
    commit('CHANGE_REGISTER_EVENT', !payload);
  },

  toggleNotificationSound ({ commit }) {
    commit('TOGGLE_NOTIFICATION_SOUND');
  },

  toggleNotificationPending ({ commit }) {
    commit('TOGGLE_NOTIFICATION_PENDING');
  },

  notify ({ state, commit }) {
    if (state.notificationSound && !state.notificationPending) {
      commit('TOGGLE_NOTIFICATION_PENDING');
    }
  },

  addBestTickers ({ commit }, payload) {
    const tickers = (payload?.tickers || []).map((t: any) => createTicker(t));
    const bestTicker = getBestTickers(tickers, payload?.settingsId);
    if (!bestTicker) return;

    commit('ADD_BEST_TICKERS', bestTicker);
  },

  async updateExchangeDrivers ({ commit }) {
    const drivers = await getExchangeDrivers({ limit: 10000 });
    commit('SET_EXCHANGE_DRIVERS', drivers);
    return drivers;
  },

};

export default actions;
