var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fg-widget',{staticClass:"exchanges-list-component",attrs:{"title":_vm.$t('menu.exchanges'),"icon":"$exchanges","refresh":_vm.updateDatagrid},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticStyle:{"height":"14px","font-size":"0.6em","font-weight":"bolder"},attrs:{"x-small":"","color":"primary"},on:{"click":_vm.newExchange}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"0.8em"},attrs:{"x-small":""}},[_vm._v("fas fa-plus")]),_vm._v(" "+_vm._s(_vm.isMobile ? _vm.$t('exchanges.actions.newMobile') : _vm.$t('exchanges.actions.new'))+" ")],1),_c('v-btn',{staticClass:"ml-2",staticStyle:{"height":"14px","font-size":"0.6em","font-weight":"bolder"},attrs:{"x-small":"","color":"info"},on:{"click":_vm.toggleModalGlobalBalance}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"0.8em"},attrs:{"x-small":""}},[_vm._v("$wallet")]),_vm._v(" "+_vm._s(_vm.$t('exchanges.headers.balances'))+" ")],1)]},proxy:true}])},[_c('fg-exchanges-list-filters',{attrs:{"resource":_vm.resource,"headers":_vm.headers,"loading":_vm.loading,"filters":_vm.filteredOptions.filters || []},on:{"change":_vm.changeFilters}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.exchanges,"item-key":"id","hide-default-footer":"","items-per-page":30,"no-data-text":_vm.$t('dataTable.noDataText'),"loading":_vm.loading},on:{"dblclick:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.userId}})]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.updatedAt))+" ")]}},{key:"item.feeTaker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.feeTaker))+" % ")]}},{key:"item.feeMaker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.feeMaker))+" % ")]}},{key:"item.strategy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("strategies.names." + (item.strategy))))+" ")]}},{key:"item.keys",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":!_vm.isMobile,"small":""},on:{"click":function () { return _vm.changeKeys(item); }}},[_c('v-icon',{attrs:{"left":_vm.isMobile}},[_vm._v("fas fa-key")]),_vm._v(" "),(_vm.isMobile)?_c('span',[_vm._v("Change Keys")]):_vm._e()],1)]}},{key:"item.balances",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":!_vm.isMobile,"small":""},on:{"click":function () { return _vm.showBalances(item); }}},[_c('v-icon',{attrs:{"left":_vm.isMobile}},[_vm._v("$wallet")]),_vm._v(" "),(_vm.isMobile)?_c('span',[_vm._v("Show Balances")]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('fg-change-status',{attrs:{"value":item.status,"loading":_vm.loading},on:{"change":function($event){return _vm.changeExchangeStatus(item)}}})]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":1000,"overlay-opacity":0.8},on:{"close":_vm.toggleModalGlobalBalance},model:{value:(_vm.showModalGlobalBalance),callback:function ($$v) {_vm.showModalGlobalBalance=$$v},expression:"showModalGlobalBalance"}},[(_vm.showModalGlobalBalance)?_c('fg-global-balances',{attrs:{"exchanges":_vm.exchanges},on:{"close":_vm.toggleModalGlobalBalance}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":1000,"overlay-opacity":0.8},on:{"close":_vm.toggleModalBalance},model:{value:(_vm.showModalBalance),callback:function ($$v) {_vm.showModalBalance=$$v},expression:"showModalBalance"}},[(_vm.showModalBalance)?_c('fg-exchange-balances',{attrs:{"balances":_vm.balances,"exchange":_vm.currentExchange.name || '',"exchangeId":_vm.currentExchange.id || ''},on:{"close":_vm.closeModalBalance}}):_vm._e()],1),(_vm.showExchangeForm)?_c('v-dialog',{attrs:{"overlay-opacity":0.8},on:{"close":_vm.closeExchangeForm},model:{value:(_vm.showExchangeForm),callback:function ($$v) {_vm.showExchangeForm=$$v},expression:"showExchangeForm"}},[(_vm.showExchangeForm)?_c('fg-exchange-form',{attrs:{"exchange":_vm.currentExchange},on:{"close":_vm.closeExchangeForm}}):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":1000,"overlay-opacity":0.8},on:{"close":_vm.toggleExchangeKeysForm},model:{value:(_vm.showExchangeKeysForm),callback:function ($$v) {_vm.showExchangeKeysForm=$$v},expression:"showExchangeKeysForm"}},[(_vm.showExchangeKeysForm)?_c('fg-exchange-keys-form',{attrs:{"id":_vm.currentExchange.id || '',"name":_vm.currentExchange.name || ''},on:{"close":_vm.closeExchangeKeysForm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }