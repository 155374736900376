


























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SettingsInterface } from '@/entities/Settings';
import FgWidget from '@/components/Dashboard/Widget.vue';
import updateSettings from '@/services/Settings/updateSettings';
import Currency from '@/entities/Currency';
import getExchangesByMarket from '@/services/Exchanges/getExchangesByMarket';

const TradeStore = namespace('Trade');

@Component({
  components: { FgWidget },
  filters: { },
})
export default class SettingsForm extends Vue {
  @Prop({ type: Object, required: true }) settings!: SettingsInterface;

  @TradeStore.Getter('strategies') strategies!: string[];

  protected loading = false;
  protected mutableOptions: any = {};
  protected exchanges: {id: string, name: string}[] = [];
  protected mutableExchanges: any[] = [];
  protected readonly currencies = Object.values(Currency);

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get strategiesOptions () {
    return this.strategies.map((s) => ({
      value: s,
      label: `[${s}] ${this.$t('strategies.names.' + s)}`,
    }));
  }

  get valueRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => {
        if (!/^(\d*\.)?\d+$/.test(value)) return 'Invalid number format';

        const number = parseFloat(value);
        if (!isNaN(number) && number >= 0.000001) return true;

        return 'Requires a valid number greater than 0.000001';
      },
    ];
  }

  get currencyRules () {
    const mutable: any = this.mutableOptions;
    return [
      (value: Currency) => !!value || 'Required.',
      (value: Currency) => {
        if (mutable.currencyPrimary === mutable.currencySecondary) return 'Not equal currencies';
        if (this.currencies.includes(value)) return true;

        return 'Invalid Currency';
      },
    ];
  }

  get percentRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => {
        if (!/^(\d*\.)?\d+$/.test(value)) return 'Invalid number format';

        const number = parseFloat(value);
        if (!isNaN(number) && number >= 0.000001 && number <= 100) return true;

        return 'Requires a valid number between 0.000001 and 100';
      },
    ];
  }

  protected async updateBaseParams () {
    if (!this.mutableOptions.currencyPrimary || !this.mutableOptions.currencySecondary || !this.mutableOptions.lotSize) {
      return;
    }
    await this.updateListExchanges();
  }

  protected clearExchanges () {
    this.mutableExchanges = [];
  }

  protected close (options: any = {}) {
    this.$emit('close', options);
  }

  protected async save () {
    const response = await updateSettings(this.settings.id, {
      strategy: this.settings.strategy,
      strategyOptions: this.mutableOptions,
      exchanges: this.mutableExchanges || null,
    });
    if (!response?.success) {
      this.$notify.error(response?.data?.message || 'Error on update settings', 'Error');
      return;
    }

    this.$notify.success('Success on update settings', 'Success');
    this.close({ reload: true });
  }

  protected async mounted () {
    this.mutableOptions = Object.assign({}, this.settings.strategyOptions || {});
    this.mutableExchanges = this.settings?.exchanges?.map((e) => e.id) || [];
    await this.updateListExchanges();
  }

  protected async updateListExchanges () {
    if (!this.mutableOptions.currencyPrimary || !this.mutableOptions.currencySecondary || !this.mutableOptions.lotSize) {
      return;
    }
    const params = {
      currencyPrimary: this.mutableOptions.currencyPrimary,
      currencySecondary: this.mutableOptions.currencySecondary,
      amount: this.mutableOptions.lotSize,
    };
    this.exchanges = await getExchangesByMarket(params, (e: any) => ({ id: e.id, name: e.name, status: e.status }));
  }
}

