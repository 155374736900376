import http, { captureException } from '@/services/http';

export const enableUser = async (id: string, params: any = {}): Promise<boolean> => {
  try {
    await http().patch(`/api/v1/users/${id}/enable`, { params });
    return true;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default enableUser;
