























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { colors } from 'vuetify/lib';
import { dateTimeFilter } from '@/filters/dateFilter';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgPieChart from '@/components/Charts/PieChart.vue';
import getTickers from '@/services/Tickers/getTickers';
import { BalanceInterface } from '@/entities/Balance';
import Currency from '@/entities/Currency';
import { currencyFilter } from '@/filters/currencyFilter';
import { ExchangeInterface } from '@/entities/Exchange';

@Component({
  components: { FgWidget, FgPieChart },
  filters: { dateTime: dateTimeFilter, currencyFilter: currencyFilter },
})
export default class GlobalBalances extends Vue {
  @Prop({ type: Array, required: true }) readonly exchanges!: ExchangeInterface[];

  protected allTickers: any = {};
  protected loading = false;
  protected currency: Currency = Currency.BRL;
  protected hideLowBalances = true;
  protected hideDisabledExchanges = true;

  protected options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontColor: '#ffffff',
      },
    },
  }

  get headers () {
    return [
      {
        text: this.$t('exchangeBalances.headers.currency').toString(),
        align: 'start',
        sortable: true,
        value: 'currency',
      },
      {
        text: this.$t('exchangeBalances.headers.amount').toString(),
        align: 'end',
        sortable: true,
        value: 'amount',
      },
      {
        text: this.$t('exchangeBalances.headers.value').toString() + ` [${this.currency}]`,
        align: 'end',
        sortable: true,
        value: 'value',
      },
      {
        text: this.isMobile ? this.$t('exchangeBalances.headers.updatedAt').toString() : '',
        align: 'end',
        sortable: true,
        value: 'updatedAt',
        width: this.isMobile ? null : '20px',
      },
    ];
  }

  get isCellPhone () {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get noChartData (): boolean {
    return this.datasource?.labels && this.datasource.labels?.length === 0;
  }

  get tickers () {
    return this.allTickers[this.currency] || {};
  }

  get hasTicker () {
    return (Object.keys(this.tickers).length > 0);
  }

  get balances (): BalanceInterface[] {
    const allBalances = {} as any;
    for (const e of this.exchanges) {
      if (this.hideDisabledExchanges && e.status === 'DISABLED') {
        continue;
      }
      for (const b of e.balances || []) {
        let amount = Number(b.amount || 0);
        if (allBalances[b.currency]?.amount) {
          amount = allBalances[b.currency].amount + Number(b.amount || 0);
        }
        allBalances[b?.currency] = { ...b, amount };
      }
    }
    return Object.values(allBalances);
  }

  get precifiedBalances (): any[] {
    if (this.hasTicker) {
      const newBalances = this.balances.map((b) => {
        const value = (b.currency === this.currency) ? Number(b.amount) : Number(b.amount) * (1 / Number(this.tickers[b.currency] || 0));
        return { ...b, value };
      });
      if (this.hideLowBalances) {
        return newBalances.filter((b) => b.amount > 0);
      }
      return newBalances;
    }
    if (this.hideLowBalances) {
      return this.balances.filter((b) => b.amount > 0);
    }
    return this.balances;
  }

  get totalBalance (): number {
    return this.precifiedBalances.reduce((ac, b) => {
      ac += b?.value || 0;
      return ac;
    }, 0);
  }

  get datasource (): any {
    if (!this.hasTicker) {
      return {
        labels: [],
        datasets: [{}],
      };
    }
    const ordernedBalances = this.precifiedBalances.map((b) => ({
      ...b,
      percent: (((b?.value || 0) * 100) / this.totalBalance),
    }));
    ordernedBalances.sort((a, b) => b.percent - a.percent);
    const labels = ordernedBalances.map((b) => b.currency);
    const data = ordernedBalances.map((b) => b.percent.toFixed(2));
    return {
      labels: labels,
      datasets: [
        {
          label: 'Balance',
          data: data,
          backgroundColor: this.getColors(labels.length),
          borderWidth: 0,
        },
      ],
    };
  }

  protected close () {
    this.$emit('close');
  }

  protected async mounted () {
    const tickers = await getTickers('BRL');
    if (tickers) {
      this.allTickers = {
        ...this.allTickers,
        [this.currency]: tickers,
      };
    }
  }

  protected getColors (size: number) {
    const avaliableColors = [
      colors.blue.lighten1,
      colors.deepOrange.lighten1,
      colors.amber.darken3,
      colors.teal.lighten1,
      colors.green.accent3,
      colors.amber.base,
      '#000000',
      '#ffffff',
      '#121212',
      '#424242', '#47B39C', '#665191', '#a05195', '#d45087', '#FFC154', '#2f4b7c', '#f95d6a', '#ff7c43', '#ffa600',
    ];
    if (size > avaliableColors.length) return avaliableColors;
    return avaliableColors.slice(0, size);
  }
}

