
















import SocketClient from '@/services/SocketClient';
import { Component, Vue, Watch } from 'vue-property-decorator';
import FgSidebar from '@/components/Dashboard/Sidebar.vue';
import FgAppToolBar from '@/components/Dashboard/AppToolBar.vue';
import FgUpdateSnackBar from '@/components/Dashboard/UpdateSnackBar.vue';
import FgSupervisorModal from '@/components/Supervisor/SupervisorModal.vue';
import { namespace } from 'vuex-class';

const AuthStore = namespace('Auth');

@Component({
  components: { FgSidebar, FgAppToolBar, FgUpdateSnackBar, FgSupervisorModal },
})
export default class App extends Vue {
  @AuthStore.Getter('currentUser') currentUser!: boolean;
  @AuthStore.Getter('currentToken') currentToken!: string;
  @AuthStore.Getter('isLogged') isLogged!: boolean;
  @AuthStore.Getter('isAdmin') isAdmin!: boolean;
  @AuthStore.Action('updateProfile') updateProfile!: CallableFunction;
  @AuthStore.Getter('supervisorUserId') supervisorUserId!: any;

  protected socketClient?: SocketClient;
  protected showSupervisorModal = false;

  public async mounted (): Promise<void> {
    this.$vuetify.theme.dark = true;
    if (this.isLogged && !this.currentUser) {
      await this.updateProfile();
    }
    if (this.isLogged) {
      this.socketClient = SocketClient.initialize(this.currentToken, this.supervisorUserId);
    }

    this.registerSupervisorEvent();
  }

  protected registerSupervisorEvent () {
    const eventFunc = (e: any) => {
      if (e.key === 'Escape' && this.showSupervisorModal) {
        this.showSupervisorModal = false;
      } else if (e.shiftKey && e.ctrlKey && e.key === 'S') {
        this.showSupervisorModal = true;
      }
    };
    if (this.isLogged && this.isAdmin) {
      document.addEventListener('keydown', eventFunc, false);
    } else {
      document.removeEventListener('keydown', eventFunc, false);
    }
  }

  protected closeSupervisorModal () {
    this.showSupervisorModal = false;
  }

  protected toolbarClick (event: string) {
    switch (event) {
      case 'supervisor': this.showSupervisorModal = true;
        break;
    }
  }

  @Watch('isLogged')
  protected changeCurrentToken () {
    if (!this.isLogged && this.socketClient) {
      this.socketClient.disconect();
      this.socketClient = undefined;
      return;
    }
    if (this.isLogged && !this.socketClient) {
      this.socketClient = SocketClient.initialize(this.currentToken, this.supervisorUserId);
    }

    this.registerSupervisorEvent();
  }

  @Watch('supervisorUserId')
  protected changeSupervisorUser () {
    this.showSupervisorModal = false;
    if (this.socketClient) {
      this.socketClient.disconect();
      this.socketClient = undefined;
    }

    this.changeCurrentToken();
  }
}
