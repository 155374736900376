




























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionRequestDetails from '@/components/ExecutionRequests/ExecutionRequestDetails.vue';
import FgExecutionRequestForm from '@/components/ExecutionRequests/ExecutionRequestForm.vue';
import FgExecutionRequestsFilters from '@/components/ExecutionRequests/ExecutionRequestsFilters.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import getExecutionRequests from '@/services/ExecutionRequests/getExecutionRequests';
import cancelExecutionRequest from '@/services/ExecutionRequests/cancelExecutionRequest';
import { createOptionsPage } from '@/entities/OptionsPaged';
import FilteredOptionsInterface, { FilterInterface } from '@/entities/FilteredOptions';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import PagedResource from '@/interfaces/PagedResource';
import { createPagedMeta } from '@/entities/PagedMeta';
import { ExecutionRequestInterface } from '@/entities/ExecutionRequest';

const TradeStore = namespace('Trade');
const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgStatus, FgExecutionRequestForm, FgExecutionRequestDetails, FgResumeTextField, FgExecutionRequestsFilters },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionRequestsList extends Vue {
  protected loading = false;

  protected resource: PagedResource<ExecutionRequestInterface> = { data: [], meta: createPagedMeta() };
  protected headers: any = [];
  protected showExecutionRequestDetails = false;
  protected showExecutionRequestForm = false;
  protected currentExecutionRequest?: ExecutionRequestInterface;
  protected options = createOptionsPage(this.resource?.meta);

  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  @TradeStore.Getter('lastExecutionRequest') lastExecutionRequest!: string;
  @AuthStore.Getter('isInternal') isInternal?: boolean;

  get requests () {
    return this.resource?.data || [];
  }

  get totalExecutions () {
    return this.resource?.meta?.total || 0;
  }

  get isMobile () {
    return this.$vuetify.breakpoint.xs;
  }

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('executionRequests.headers.status').toString(),
        align: 'center',
        sortable: false,
        value: 'status',
        width: '60px',
      },
      {
        text: this.$t('executionRequests.headers.id').toString(),
        align: 'start',
        sortable: false,
        filterable: true,
        value: 'id',
        width: '60px',
      },
      {
        text: this.$t('executionRequests.headers.createdAt').toString(),
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
      {
        text: this.$t('executionRequests.headers.executedAt').toString(),
        align: 'start',
        sortable: false,
        value: 'executedAt',
      },
      {
        text: this.$t('executionRequests.headers.market').toString(),
        align: 'start',
        sortable: false,
        value: 'market',
      },
      {
        text: this.$t('executionRequests.headers.operation').toString(),
        align: 'start',
        sortable: false,
        value: 'operation',
      },
      {
        text: this.$t('executionRequests.headers.amount').toString(),
        align: 'end',
        sortable: false,
        value: 'amount',
      },
      {
        text: this.$t('executionRequests.headers.amountExecuted').toString(),
        align: 'end',
        sortable: false,
        value: 'amountExecuted',
      },
      {
        text: this.$t('executionRequests.headers.price').toString(),
        align: 'end',
        sortable: false,
        value: 'price',
      },
      {
        text: this.$t('executionRequests.headers.priceExecuted').toString(),
        align: 'end',
        sortable: false,
        value: 'priceExecuted',
      },
      {
        text: this.$t('executionRequests.headers.actions').toString(),
        align: 'end',
        sortable: false,
        value: 'actions',
      },
    ];
    if (this.isInternal) {
      this.headers.splice(2, 0, {
        text: this.$t('executionRequests.headers.userId').toString(),
        align: 'start',
        sortable: false,
        filterable: true,
        value: 'userId',
        width: '60px',
      });
    }
  }

  protected async mounted () {
    await this.updateDatagrid();
  }

  protected async updateDatagrid () {
    this.loading = true;
    try {
      this.resource = await getExecutionRequests({
        ...this.filteredOptions,
        populate: ['executions', 'executions.orders', 'executions.orders.exchange'],
      });
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  protected toggleExecutionRequestDetails () {
    this.showExecutionRequestDetails = !this.showExecutionRequestDetails;
  }

  protected toggleExecutionRequestForm () {
    this.showExecutionRequestForm = !this.showExecutionRequestForm;
  }

  protected rowClicked (_: any, row: any) {
    this.currentExecutionRequest = row.item;
    this.toggleExecutionRequestDetails();
  }

  protected hasCancel (request: ExecutionRequestInterface) {
    if (!['OPEN', 'ERROR', 'PARTIAL'].includes(request.status || '')) return false;

    return true;
  }

  protected async executeCancelRequest (request: ExecutionRequestInterface) {
    if (!request.id || !this.hasCancel(request)) return false;

    const response = await cancelExecutionRequest(request.id);
    if (!response.success) {
      this.$notify.error(
        response.message || this.$t('executionRequests.actions.errorOnCancel.message'),
        this.$t('executionRequests.actions.errorOnCancel.title'),
      );
      return;
    }
    request.status = 'CANCELLED';
    this.$notify.success(
      this.$t('executionRequests.actions.successOnCancel.message'),
      this.$t('executionRequests.actions.successOnCancel.title'),
    );
  }

  @Watch('options')
  protected async changeOptions (value: any) {
    value.limit = value.itemsPerPage;
    this.filteredOptions.options = value;
    await this.updateDatagrid();
  }

  @Watch('lastExecutionRequest')
  protected async updatedExecutionRequestList () {
    if (this.resource?.meta?.page === 1) {
      await this.updateDatagrid();
    }
  }
}

