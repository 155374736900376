import { TickerInterface } from '@/entities/Ticker';
export interface BestTickers {
  createdAt: Date;
  amount: number;
  buy: number;
  buyExchange: string;
  sell: number;
  sellExchange: string;
  market: string;
  settingsId?: string;
}

const makeBestTicker = (tickerBuy: TickerInterface, tickerSell: TickerInterface, settingsId?: string): BestTickers | false => {
  if (tickerBuy.buy <= 0 || tickerSell.sell <= 0) return false;
  return {
    createdAt: new Date(tickerBuy.createdAt),
    amount: tickerBuy.amount,
    buy: tickerBuy.buy * (1 + (tickerBuy.fee / 100)),
    buyExchange: tickerBuy.exchange?.name || '?',
    sell: tickerSell.sell * (1 - (tickerSell.fee / 100)),
    sellExchange: tickerSell.exchange?.name || '?',
    market: `${tickerBuy.currencyPrimary}/${tickerBuy.currencySecondary}`,
    settingsId,
  };
};

const getBestBuyTicker = (tickers: TickerInterface[]): TickerInterface | false => {
  const filteredTickers = tickers.filter((ticker) => {
    return ticker.buy > 0;
  });

  const firstTicker = filteredTickers[0] || false;
  if (!firstTicker) {
    return false;
  }

  const min = {
    ticker: firstTicker,
    value: firstTicker.buy * (1 + (firstTicker.fee / 100)),
  };

  for (const ticker of filteredTickers) {
    const currentValue = ticker.buy * (1 + (ticker.fee / 100));
    if (currentValue < min.value) {
      min.ticker = ticker;
      min.value = currentValue;
    }
  }

  return min.ticker;
};

const getBestSellTicker = (tickers: TickerInterface[]): TickerInterface | false => {
  const filteredTickers = tickers.filter((ticker) => ticker.sell > 0);

  const firstTicker = filteredTickers[0] || false;
  if (!firstTicker) {
    return false;
  }

  const max = {
    ticker: firstTicker,
    value: firstTicker.sell * (1 - (firstTicker.fee / 100)),
  };

  for (const ticker of filteredTickers) {
    const currentValue = ticker.sell * (1 - (ticker.fee / 100));
    if (currentValue > max.value) {
      max.ticker = ticker;
      max.value = currentValue;
    }
  }

  return max.ticker;
};

const getBestTickers = (tickers: TickerInterface[], settingsId?: string): BestTickers | false => {
  if (!tickers || tickers.length === 0) return false;
  if (tickers.length === 1) return makeBestTicker(tickers[0], tickers[0], settingsId);

  const tickerBuy = getBestBuyTicker(tickers);
  const tickerSell = getBestSellTicker(tickers);

  if (!tickerBuy || !tickerSell) return false;

  return makeBestTicker(tickerBuy, tickerSell, settingsId);
};

export default getBestTickers;

