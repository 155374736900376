
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { },
  filters: { currency: currencyFilter },
})
export default class VolumeForExchange extends Vue {
  @Prop({ type: Object, required: true }) result!: any;

  get headers () {
    return [
      {
        text: this.$t('reports.results.exchange.headers.name').toString(),
        align: 'start',
        sortable: false,
        value: 'name',
        width: '10%',
      },
      {
        text: this.$t('reports.results.exchange.headers.currency').toString(),
        align: 'start',
        sortable: false,
        value: 'currency',
        width: '10%',
      },
      {
        text: this.$t('reports.results.exchange.headers.finished').toString(),
        align: 'end',
        sortable: false,
        value: 'finishedValue',
        width: '30%',
      },
      {
        text: this.$t('reports.results.exchange.headers.partial').toString(),
        align: 'end',
        sortable: false,
        value: 'partialValue',
        width: '30%',
      },
      {
        text: this.$t('reports.results.exchange.headers.value').toString(),
        align: 'end',
        sortable: false,
        value: 'value',
        width: '30%',
      },
    ];
  }

  get exchanges () {
    const list: any = [];
    for (const exchangeId of Object.keys(this.result)) {
      for (const currency of Object.keys(this.result[exchangeId])) {
        const current = this.result[exchangeId][currency];
        list.push({
          ...current,
          currency,
          id: `${exchangeId}`,
          key: `${exchangeId}-${currency}`,
        });
      }
    }
    return list;
  }
}

