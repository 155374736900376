





























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgLineChart from '@/components/Charts/LineChart.vue';
import { timeFilter } from '@/filters/dateFilter';
import { BestTickers } from '@/helpers/bestBuySellTicker';
import { currencyFilter } from '@/filters/currencyFilter';

interface ChartItem {
  x: string;
  y: number;
  exchange: string;
}

const TradeStore = namespace('Trade');

@Component({ components: { FgWidget, FgLineChart } })
export default class PriceMonitor extends Vue {
  @TradeStore.State('bestTickers') bestTickers?: { [settingsId: string]: BestTickers[] };

  protected market = '';
  protected margin = 0.1;

  protected options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: true,
    },
    parsing: {
      xAxisKey: 'value',
      yAxisKey: 'createdAt',
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: false,
        },
        gridLines: {
          display: true,
          color: '#171717',
        },
      }],
      xAxes: [{
        gridLines: {
          display: false,
        },
      }],
    },
    tooltips: {
      mode: 'x',
      intersect: false,
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const exchange = data.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]?.exchange || '';
          const op = tooltipItem.datasetIndex === 0 ? 'Sell' : tooltipItem.datasetIndex === 1 ? 'Buy' : 'Margin';
          return `${op}: ${currencyFilter(tooltipItem.value, undefined, 2)} (${exchange})`;
        },
      },
    },
  }

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get currentMarket () {
    if (this.market) return this.market;

    const markets = Object.keys(this?.bestTickers || {});
    return markets[0] || '';
  }

  get selectList (): string[] {
    return Object.keys(this?.bestTickers || {});
  }

  get noChartData (): boolean {
    return this.datasource?.labels && this.datasource.labels?.length === 0;
  }

  get datasource (): any {
    if (!this.currentMarket || !this.bestTickers?.[this.currentMarket]) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const labels: string[] = [];
    const buyDataset: ChartItem[] = [];
    const sellDataset: ChartItem[] = [];
    const buyWithMarginDataset: ChartItem[] = [];
    for (const ticker of this.bestTickers[this.currentMarket] || []) {
      const label = timeFilter(ticker.createdAt);
      labels.push(label);
      buyDataset.push({
        x: label,
        y: ticker.buy,
        exchange: ticker.buyExchange,
      });

      sellDataset.push({
        x: label,
        y: ticker.sell,
        exchange: ticker.sellExchange,
      });

      buyWithMarginDataset.push({
        x: label,
        y: ticker.buy * (1 + (this.margin / 100)),
        exchange: 'Buy With Margin',
      });
    }
    return {
      labels: labels,
      datasets: [
        {
          label: this.$t('tickers.priceMonitor.labels.bestSell').toString(),
          borderColor: 'red',
          borderWidth: 1,
          data: sellDataset,
        }, {
          label: this.$t('tickers.priceMonitor.labels.bestBuy').toString(),
          borderColor: 'blue',
          borderWidth: 1,
          data: buyDataset,
        }, {
          label: this.$t('tickers.priceMonitor.labels.margin').toString(),
          borderColor: 'yellow',
          borderWidth: 1,
          data: buyWithMarginDataset,
        },
      ],
    };
  }

  protected mounted () {
    const markets = Object.keys(this?.bestTickers || {});
    this.market = markets[0] || '';
  }
}

