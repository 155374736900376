





























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import unlockExecutionRequest from '@/services/ExecutionRequests/unlockExecutionRequest';
import retryExecutionRequest from '@/services/ExecutionRequests/retryExecutionRequest';
import { ExecutionRequestInterface } from '@/entities/ExecutionRequest';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionsDataTable from '@/components/Executions/ExecutionsDataTable.vue';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgExecutionRequestCheckersList from '@/components/ExecutionRequests/ExecutionRequestCheckersList.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { FgWidget, FgStatus, FgExecutionsDataTable, FgExecutionRequestCheckersList },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionDetails extends Vue {
  @Prop({ type: Object, required: true }) executionRequest!: ExecutionRequestInterface;

  protected showCheckers = false;

  get canRetry (): boolean {
    return this.executionRequest.status === 'ERROR' && this.executionRequest.statusSecondary !== 'LOCKED';
  }

  get canUnlockAction (): boolean {
    if (!this.executionRequest || !this.executionRequest.status) return false;
    if (this.executionRequest.statusSecondary !== 'LOCKED') return false;
    if (!['OPEN'].includes(this.executionRequest.status)) return false;

    return true;
  }

  get exchanges (): string {
    if (!this.executionRequest.exchanges || this.executionRequest.exchanges.length === 0) {
      return this.$t('executionRequests.all').toString();
    }

    return this.executionRequest.exchanges.join(', ');
  }

  get hasGetShowCheckers (): boolean {
    if (!this.executionRequest || !this.executionRequest.status) return false;

    // return ['OPEN', 'PARTIAL', 'ERROR'].includes(this.executionRequest.status);
    return true;
  }

  protected toogleShowCheckers () {
    if (!this.executionRequest) {
      this.showCheckers = false;
      return;
    }
    this.showCheckers = !this.showCheckers;
  }

  protected async unlockExecutionRequestHandler () {
    if (!this.canUnlockAction || !this.executionRequest.id) return false;

    const response = await unlockExecutionRequest(this.executionRequest.id);
    if (!response.success) {
      this.$notify.error(response.message || this.$t('executionRequests.actions.unlock.error'), this.$t('actions.error'));
      return;
    }

    this.executionRequest.statusSecondary = '';
    this.$notify.success(this.$t('executionRequests.actions.unlock.success'), this.$t('actions.success'));
  }

  protected async retryExecutionRequestHandler () {
    if (!this.canRetry || !this.executionRequest.id) return false;

    const response = await retryExecutionRequest(this.executionRequest.id);
    if (!response.success) {
      this.$notify.error(response.message || this.$t('executionRequests.actions.retry.error'), this.$t('actions.error'));
      return;
    }

    this.executionRequest.status = 'OPEN';
    for (const i in this.executionRequest.executions) {
      if (this.executionRequest.executions[i].status === 'ERROR') {
        this.executionRequest.executions[i].status = 'CANCELLED';
      }
    }
    this.$notify.success(this.$t('executionRequests.actions.retry.success'), this.$t('actions.success'));
  }

  protected close () {
    this.$emit('close');
  }
}

