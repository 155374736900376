export interface PagedMetaInterface {
  page: number;
  limit: number,
  total: number,
  totalPages: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  nextPage: string,
  prevPage: string,
}

export const createPagedMeta = (data: any = {}): PagedMetaInterface => {
  return {
    page: Number(data.page || 1),
    limit: Number(data.limit || 10),
    total: Number(data.total || 0),
    totalPages: Number(data.totalPages || 1),
    hasPrevPage: data.hasPrevPage || false,
    hasNextPage: data.hasNextPage || false,
    nextPage: data.nextPage || '',
    prevPage: data.prevPage || '',
  };
};

export default { createPagedMeta };
