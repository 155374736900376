import createExchange, { ExchangeInterface } from './Exchange';

export interface TickerInterface {
  id?: string;
  currencyPrimary?: string;
  currencySecondary?: string;
  amount: number;
  buy: number;
  sell: number;
  fee: number;
  createdAt: Date;
  exchange?: ExchangeInterface;
  settingsId?: string;
}

export const createTicker = (data: any): TickerInterface => ({
  id: data.id || undefined,
  currencyPrimary: data.currencyPrimary || undefined,
  currencySecondary: data.currencySecondary || undefined,
  amount: Number(data.amount || 0),
  buy: Number(data.buy || 0),
  sell: Number(data.sell || 0),
  fee: Number(data.fee || 0),
  createdAt: new Date(data.createdAt),
  exchange: data.exchange ? createExchange(data.exchange) : undefined,
  settingsId: data.settingsId || undefined,
});

export default createTicker;
