












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ChangeStatus extends Vue {
  @Prop({ type: String, required: false, default: 'DISABLED' }) value!: string;
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean;

  protected status = false;

  public mounted () {
    this.status = this.value === 'ENABLED';
  }

  @Watch('value')
  protected changeValue (value: string) {
    this.status = value === 'ENABLED';
  }

  protected changeStatus () {
    this.$emit('change', this.status);
  }
}
