import authorizer from '@/services/Auth/authorizer';
import store from '@/store';
import { Route } from 'vue-router';

export default async (to: Route, _: Route, next: CallableFunction) => {
  if (!store.getters['Auth/isLogged']) {
    return next({ name: 'Login' });
  }

  const hasAuthorization = await authorizer(store, to?.meta?.resource, to?.meta?.permissions);
  if (!hasAuthorization) {
    const routerName = store.getters['Auth/isLogged'] ? 'Home' : 'Login';
    return next({ name: routerName });
  }

  next();
};
