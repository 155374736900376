export interface BalanceInterface {
  amount: number;
  currency: string;
  exchangeId: string;
  updatedAt?: Date;
}

export const createBalance = (data: any): BalanceInterface => ({
  amount: Number(data.amount || 0),
  currency: data.currency || undefined,
  exchangeId: data.exchangeId || undefined,
  updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
});
