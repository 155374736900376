import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { isInternal, isAdmin } from '@/helpers/authHelper';

const getters: GetterTree<AuthState, AuthState> = {
  isLogged (state) {
    return !!state?.session?.token;
  },
  isSession (state) {
    return !!(state?.session?.token);
  },
  isEnabled2fa (state) {
    return !!(state?.user?.isEnabled2fa || false);
  },
  isInternal (state) {
    return isInternal(state?.user || {});
  },
  isAdmin (state) {
    return isAdmin(state?.user || {});
  },
  isSimulation (state) {
    return state?.simulation || false;
  },
  currentUser (state) {
    return state?.user || false;
  },
  currentToken (state) {
    return state?.session?.token || false;
  },

  supervisorUserId (state): string | undefined {
    return state?.supervisorUser?.id || undefined;
  },
};

export default getters;
