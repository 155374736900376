import { createExchangeDriver, ExchangeDriverInterface } from '@/entities/ExchangeDriver';
import http, { captureException } from '@/services/http';

export const getExchangeDrivers = async (params: any = {}): Promise<ExchangeDriverInterface[]> => {
  try {
    const response = await http().get('/api/v1/exchange-drivers', { params });
    if (!response?.data?.data || !Array.isArray(response?.data?.data)) return [];

    return response.data.data.map((s: any) => createExchangeDriver(s));
  } catch (e) {
    captureException(e);
    console.error(e);
    return [];
  }
};

export default getExchangeDrivers;
