var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fg-widget',{staticClass:"execution-requests-list-component",attrs:{"title":_vm.$t('executionRequests.title'),"icon":"$executionRequests","refresh":_vm.updateDatagrid},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticStyle:{"height":"14px","font-size":"0.6em","font-weight":"bolder"},attrs:{"x-small":"","color":"primary"},on:{"click":_vm.toggleExecutionRequestForm}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"0.8em"},attrs:{"x-small":""}},[_vm._v("fas fa-plus")]),_vm._v(" "+_vm._s(_vm.isMobile ? _vm.$t('executionRequests.actions.newMobile') : _vm.$t('executionRequests.actions.new'))+" ")],1)]},proxy:true}])},[_c('fg-execution-requests-filters',{attrs:{"resource":_vm.resource,"headers":_vm.headers,"loading":_vm.loading,"filters":_vm.filteredOptions.filters || []},on:{"change":_vm.changeFilters}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.requests,"item-key":"id","no-data-text":_vm.$t('dataTable.noDataText'),"options":_vm.options,"server-items-length":_vm.totalExecutions,"loading":_vm.loading,"footer-props":{
            itemsPerPageOptions: [5, 10, 30, 100],
            showFirstLastPage: true,
            itemsPerPageText: _vm.$t('dataTable.rowsPerPage'),
          }},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.userId",fn:function(ref){
          var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.userId}})]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('fg-status',{attrs:{"name":item.status,"description":item.statusDescription || _vm.$t(("status." + (item.status))),"icon":!_vm.isMobile}})]}},{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt))+" ")]}},{key:"item.executedAt",fn:function(ref){
          var item = ref.item;
return [(item.executedAt )?_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(item.executedAt))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.market",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(((item.currencySecondary) + "/" + (item.currencyPrimary)))+" ")]}},{key:"item.margin",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.margin,null, 4))+" % ")]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,item.currencySecondary))+" ")]}},{key:"item.amountExecuted",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amountExecuted,item.currencySecondary))+" ")]}},{key:"item.fee",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.fee,item.currencyPrimary))+" ")]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,item.currencyPrimary))+" ")]}},{key:"item.priceExecuted",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.priceExecuted,item.currencyPrimary))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.hasCancel(item))?_c('v-btn',{staticClass:"error",attrs:{"x-small":""},on:{"click":function () { return _vm.executeCancelRequest(item); }}},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("$cancel")]),_vm._v(" "+_vm._s(_vm.$t("actions.cancel"))+" ")],1):_c('span',[_vm._v("-")])]}}],null,true)}),_c('v-dialog',{attrs:{"overlay-opacity":0.8,"max-width":1000},on:{"close":_vm.toggleExecutionRequestForm},model:{value:(_vm.showExecutionRequestForm),callback:function ($$v) {_vm.showExecutionRequestForm=$$v},expression:"showExecutionRequestForm"}},[(_vm.showExecutionRequestForm)?_c('fg-execution-request-form',{on:{"close":_vm.toggleExecutionRequestForm}}):_vm._e()],1),_c('v-dialog',{attrs:{"overlay-opacity":0.8},on:{"close":_vm.toggleExecutionRequestDetails},model:{value:(_vm.showExecutionRequestDetails),callback:function ($$v) {_vm.showExecutionRequestDetails=$$v},expression:"showExecutionRequestDetails"}},[(_vm.showExecutionRequestDetails)?_c('fg-execution-request-details',{attrs:{"executionRequest":_vm.currentExecutionRequest},on:{"close":_vm.toggleExecutionRequestDetails}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }