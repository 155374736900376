















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class UpdateSnackBar extends Vue {
  protected reloading = false;
  protected showUpdateAlert = false;

  protected created () {
    document.addEventListener('newVersionReleased', this.toggleRefresh, { once: true });
  }

  protected destroyed () {
    document.removeEventListener('newVersionReleased', this.toggleRefresh);
  }

  protected toggleRefresh () {
    this.showUpdateAlert = !this.showUpdateAlert;
  }

  protected reloadApp () {
    if (this.reloading) return;
    this.showUpdateAlert = false;
    this.reloading = true;
    window.location.reload();
  }
}
