









































import { namespace } from 'vuex-class';
import { Vue, Component } from 'vue-property-decorator';
import FgWidget from '@/components/Dashboard/Widget.vue';
import { UserInterface } from '../../entities/User';
import getUsers from '../../services/Users/getUsers';
import { createOptionsPage } from '../../entities/OptionsPaged';
import { createPagedMeta } from '../../entities/PagedMeta';
import PagedResource from '../../interfaces/PagedResource';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget },
})
export default class SupervisorModal extends Vue {
  @AuthStore.Getter('isLogged') isLogged!: boolean;
  @AuthStore.Getter('isAdmin') isAdmin!: boolean;
  @AuthStore.Action('changeSupervisorUser') changeSupervisorUser!: CallableFunction;

  protected resource: PagedResource<UserInterface> = { data: [], meta: createPagedMeta({ limit: 200 }) };
  protected options = createOptionsPage(this.resource?.meta);
  protected selectedUser: {id: string, email: string, name: string, text: string} | null = null;
  protected loading = false;

  get usersSelect () {
    return this.resource.data.map((u) => ({
      id: u.id,
      name: u.name,
      email: u.email,
      text: `${u.name} (${u.email})`,
    }));
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  protected async mounted () {
    if (!this.isLogged || !this.isAdmin) {
      return this.close();
    }

    this.resource = await getUsers({
      filters: [],
      options: this.options,
      select: ['id', 'name', 'email'],
    });
  }

  protected async save () {
    if (!this.selectedUser || !this.selectedUser.id) {
      console.log('Notifica erro');
      return;
    }

    await this.changeSupervisorUser({
      id: this.selectedUser.id,
      name: this.selectedUser?.name,
      email: this.selectedUser?.email,
    });
    this.$emit('change', this.selectedUser);
  }

  protected clearUserSelect () {
    this.selectedUser = null;
  }

  protected close () {
    this.$emit('close');
  }
}

