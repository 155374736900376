

































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import FgSettingsListFilters from '@/components/Settings/SettingsListFilters.vue';
import FgChangeStatus from '@/components/Core/ChangeStatus.vue';
import FgSettingsForm from './SettingsForm.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { SettingsInterface } from '@/entities/Settings';
import enableSettings from '@/services/Settings/enableSettings';
import disableSettings from '@/services/Settings/disableSettings';
import PagedResource from '../../interfaces/PagedResource';
import FilteredOptionsInterface, { FilterInterface } from '../../entities/FilteredOptions';
import { createPagedMeta } from '../../entities/PagedMeta';
import { createOptionsPage } from '../../entities/OptionsPaged';
import getSettings from '../../services/Settings/getSettings';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgStatus, FgSettingsListFilters, FgResumeTextField, FgChangeStatus, FgSettingsForm },
  filters: { dateTime: dateTimeFilter },
})
export default class AppToolBar extends Vue {
  @AuthStore.Getter('isInternal') isInternal?: boolean;

  protected resource: PagedResource<SettingsInterface> = { data: [], meta: createPagedMeta() };
  protected options = createOptionsPage(this.resource?.meta);
  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  protected headers: any[] = [];
  protected loading = false;
  protected showFormOptions = false;
  protected currentSettings: any = {}

  get settings () {
    return this?.resource?.data || [];
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('settings.headers.id').toString(),
        align: 'start',
        sortable: true,
        value: 'id',
        width: '80px',
        filterable: true,
      },
      {
        text: this.$t('settings.headers.updatedAt').toString(),
        align: 'start',
        sortable: true,
        value: 'updatedAt',
      },
      {
        text: this.$t('settings.headers.name').toString(),
        align: 'start',
        sortable: true,
        value: 'name',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('settings.headers.strategy').toString(),
        align: 'start',
        sortable: false,
        value: 'strategy',
      },
      {
        text: this.$t('settings.headers.strategyOptions').toString(),
        align: 'start',
        sortable: false,
        value: 'strategyOptions',
      },
      {
        text: this.$t('settings.headers.action').toString(),
        align: 'center',
        sortable: false,
        value: 'action',
        width: '80px',
      },
      {
        text: this.$t('settings.headers.status').toString(),
        align: 'center',
        sortable: false,
        value: 'status',
        width: '80px',
      },
    ];

    if (this.isInternal) {
      this.headers.splice(1, 0, {
        text: this.$t('settings.headers.userId').toString(),
        align: 'start',
        sortable: true,
        value: 'userId',
        width: '80px',
        filterable: true,
      });
    }
  }

  protected async updateDatagrid () {
    try {
      this.loading = true;
      this.resource = await getSettings({
        ...this.filteredOptions,
        populate: ['exchanges'],
      });

      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  public async mounted () {
    await this.updateDatagrid();
  }

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  protected async closeEditForm (options: any = {}) {
    if (options?.reload) {
      await this.updateDatagrid();
    }
    this.toggleSettingsForm();
  }

  protected editSettings (settings: SettingsInterface) {
    this.currentSettings = settings;
    this.showFormOptions = true;
  }

  protected rowClicked (_: any, row: any) {
    this.editSettings(row.item);
  }

  protected toggleSettingsForm () {
    this.showFormOptions = !this.showFormOptions;
  }

  protected async changeSettingsStatus (settings: SettingsInterface) {
    if (this.loading || !settings) return false;
    this.loading = true;

    await ((settings.status === 'ENABLED') ? disableSettings(settings.id) : enableSettings(settings.id));
    await this.updateDatagrid();
    this.loading = false;
  }

  protected getStrategyString (options: any): string {
    const items: string[] = [];
    if (options.currencyPrimary && options.currencySecondary) {
      items.push(`${this.$t('settings.options.pair')}: ${options.currencySecondary}/${options.currencyPrimary}`);
    }
    if (options.lotSize) {
      items.push(`${this.$t('settings.options.lotSize')}: ${options.lotSize}`);
    }
    if (options.margin) {
      items.push(`${this.$t('settings.options.margin')}: ${options.margin}`);
    }
    if (options.minGain) {
      items.push(`${this.$t('settings.options.minGain')}: ${options.minGain}`);
    }

    const main = items.join(' | ');
    let rebalance = '';
    if (options.rebalance) {
      rebalance = `<br/>${this.$t('settings.options.rebalance')}: ${options.primaryMinRatio || 0}/${options.secondaryMinRatio || 100}`;
      rebalance += ` | ${this.$t('settings.options.minGain')}: ${options.minRebalanceGain || 0}`;
    }

    return main + rebalance;
  }
}

