






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ResumeTextField extends Vue {
  protected isResumed = true;

  @Prop({ type: String, required: true }) text!: string;
  @Prop({ type: Number, required: false, default: 8 }) length!: number;
  @Prop({ type: Boolean, required: false, default: false }) revert!: boolean;

  get resumed () {
    if (this.isResumed) {
      if (this.text.length <= this.length) return this.text;
      return this.revert
        ? '...' + (this.text.substr(this.text.length - this.length))
        : this.text.substr(0, this.length) + '...';
    }
    return this.text;
  }
}
