











import { Component, Vue } from 'vue-property-decorator';
import FgPriceMonitor from '@/components/Tickers/PriceMonitor.vue';
import FgEventsList from '@/components/Events/EventsList.vue';
import FgResultWidgets from '@/components/Dashboard/ResultWidgets.vue';
import FgExecutionsStream from '@/components/Executions/ExecutionsStream.vue';

@Component({
  components: { FgPriceMonitor, FgExecutionsStream, FgEventsList, FgResultWidgets },
})
export default class Home extends Vue {
  protected breadcrumbs: any;

  public beforeMount () {
    this.breadcrumbs = [
      {
        text: this.$t('menu.dashboard'),
        to: { name: 'Home' },
        exact: true,
        disabled: false,
      },
    ];
  }
}
