import createExchange, { ExchangeInterface } from './Exchange';

export enum OrderOperation {
  BUY = 'BUY',
  SELL = 'SELL'
}
export interface OrderInterface {
  id?: string;
  status?: string;
  opportunityId?: string;
  operation?: OrderOperation;
  market?: string;
  currencyPrimary?: string;
  currencySecondary?: string;
  amount: number;
  amountOrig: number;
  price: number;
  priceOrig: number;
  value: number;
  valueOrig: number;
  fee: number;
  feeCurrency?: string;
  feePrice: number;
  netValue: number;
  createdAt: Date;
  updatedAt: Date;
  exchange?: ExchangeInterface;
  description?: string;
  exchangeOrderId?: string,
}

const getNetValue = (data: any): number => {
  if (!data.operation) return 0;

  if (data.operation === OrderOperation.BUY) {
    return Number(data.value || 0) + (Number(data.fee || 0) * Number(data.feePrice || 1));
  }
  return Number(data.value || 0) - (Number(data.fee || 0) * Number(data.feePrice || 1));
};

export const createOrder = (data: any): OrderInterface => ({
  id: data.id || undefined,
  status: data.status || undefined,
  opportunityId: data.opportunityId || undefined,
  operation: data.operation || undefined,
  market: data.market || undefined,
  currencyPrimary: data.currencyPrimary || undefined,
  currencySecondary: data.currencySecondary || undefined,
  amount: Number(data.amount || 0),
  amountOrig: Number(data.amountOrig || 0),
  price: Number(data.price || 0),
  priceOrig: Number(data.priceOrig || 0),
  value: Number(data.value || 0),
  valueOrig: Number(data.valueOrig || 0),
  fee: Number(data.fee || 0),
  feeCurrency: data.feeCurrency || undefined,
  feePrice: Number(data.feePrice || 0),
  netValue: getNetValue(data),
  exchangeOrderId: data.exchangeOrderId || undefined,
  description: data.description || undefined,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.createdAt),
  exchange: data.exchange ? createExchange(data.exchange) : undefined,
});

export default createOrder;
