









































































































































import { Vue, Component } from 'vue-property-decorator';
import { NewExecutionRequestInterface } from '@/entities/ExecutionRequest';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionsDataTable from '@/components/Executions/ExecutionsDataTable.vue';
import FgStatus from '@/components/Dashboard/Status.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { OrderOperation } from '@/entities/Order';
import Currency from '@/entities/Currency';
import getSelectExchanges from '@/services/Exchanges/getSelectExchanges';
import saveExecutionRequest from '@/services/ExecutionRequests/saveExecutionRequest';

@Component({
  components: { FgWidget, FgStatus, FgExecutionsDataTable },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionRequestForm extends Vue {
  protected readonly currencies = Object.values(Currency);
  protected loading = false;
  protected isValid = false;
  protected operation = 0;
  protected exchanges: {id: string, name: string}[] = [];

  protected initexecutionRequest?: NewExecutionRequestInterface = {
    clientId: '',
    operation: OrderOperation.BUY,
    currencyPrimary: undefined,
    currencySecondary: undefined,
    margin: 0.01,
    amount: 0,
    price: 0,
  };

  protected executionRequest: NewExecutionRequestInterface = Object.assign({}, this.initexecutionRequest);

  get currencyRules () {
    const mutable: any = this.executionRequest;
    return [
      (value: Currency) => !!value || 'Required.',
      (value: Currency) => {
        if (mutable.currencyPrimary === mutable.currencySecondary) return 'Not equal currencies';
        if (this.currencies.includes(value)) return true;

        return 'Invalid Currency';
      },
    ];
  }

  get valueRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => {
        if (!/^(\d*\.)?\d+$/.test(value)) return 'Invalid number format';

        const number = parseFloat(value);
        if (!isNaN(number) && number >= 0.000001) return true;

        return 'Requires a valid number greater than 0.000001';
      },
    ];
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get validRequest (): boolean {
    return true;
  }

  protected validate () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.form.validate();
  }

  protected async updateListExchanges () {
    this.exchanges = await getSelectExchanges<{id: string, name: string}>({
      select: ['id', 'name'],
      orderBy: 'name',
    }, (e: any) => ({ id: e.id, name: e.name }));
  }

  protected toogleOperation () {
    this.executionRequest.operation = this.executionRequest.operation === OrderOperation.BUY
      ? OrderOperation.SELL
      : OrderOperation.BUY;
  }

  protected initExecutionRequest () {
    this.executionRequest = Object.assign({}, this.initexecutionRequest);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.form.resetValidation();
  }

  protected async save () {
    this.validate();
    if (!this.isValid) return false;

    const response = await saveExecutionRequest(this.executionRequest);
    if (!response.success) {
      this.$notify.error(
        response.message || this.$t('executionRequests.actions.errorOnSave.message'),
        this.$t('executionRequests.actions.errorOnSave.title'),
      );
      return;
    }

    this.$notify.success(
      this.$t('executionRequests.actions.successOnSave.message'),
      this.$t('executionRequests.actions.successOnSave.title'),
    );
    this.close();
  }

  protected close () {
    this.$emit('close');
  }

  protected mounted () {
    this.initExecutionRequest();
    this.updateListExchanges();
  }
}

