export enum Currency {
  BRL = 'BRL',
  USD = 'USD',

  BTC = 'BTC',
  USDT = 'USDT',
  USDC = 'USDC',
  CBRL = 'CBRL',
  BUSD = 'BUSD',
  BNB = 'BNB',
  ETH = 'ETH',
  ADA = 'ADA',
  XRP = 'XRP',
  LTC = 'LTC',
  LINK = 'LINK',
  BCH = 'BCH',
  XML = 'XML',
  DOGE = 'DOGE',
  XMR = 'XMR',
  BSV = 'BSV',
  DASH = 'DASH',
  DCR = 'DCR',
  ETC = 'ETC',
  CHZ = 'CHZ',
  AXS = 'AXS',
}

export const isEnabledCurrency = (currency: string | Currency): boolean => {
  return Object.values(Currency).includes(currency as Currency);
};

export const getPrecisionByCurrency = (currency: string | Currency): number => {
  switch (currency) {
    case Currency.BRL:
    case Currency.USDT:
      return 4;
    case Currency.BTC:
      return 8;
    default:
      return 8;
  }
};

export default Currency;
