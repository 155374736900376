import http, { captureException } from '@/services/http';

export const getTickers = async (currency: string, params: any = {}): Promise<any> => {
  try {
    const response = await http().get(`/api/v1/tickers/${currency}`, { params });
    return response?.data?.data || false;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default getTickers;
