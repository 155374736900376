import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';

import en from '@/locales/en';

import notifyPlugin from '@/plugins/notifyPlugin';

// Sentry integration
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

import '@/themes/current/main.scss';

Vue.config.productionTip = false;

Vue.use(notifyPlugin);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: en },
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      logErrors: true,
    }),
  ],
  release: process.env.VUE_APP_SENTRY_RELEASE_VERSION,
});
