import { captureException } from '@/services/http';

export const logout = async () => {
  try {
    // await http().delete('/api/v1/auth/logout');
    return true;
  } catch (e) {
    captureException(e);
    return false;
  }
};

export default logout;
