import { MutationTree } from 'vuex';
import { DashboardState } from './types';

const mutations: MutationTree<DashboardState> = {
  CHANGE_THEME (state, theme) {
    state.theme = theme;
  },

  TOOGLE_SHOW_SIDEBAR (state) {
    state.showSidebar = !state.showSidebar;
  },

  TOOGLE_SHOW_SIDEBAR_MINIFIED (state) {
    state.showSidebarMinified = !state.showSidebarMinified;
  },
};

export default mutations;
