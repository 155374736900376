import http, { captureException } from '@/services/http';

export const updateSettings = async (id: string, data: any = {}): Promise<{ success: boolean, data: any }> => {
  try {
    const response = await http().put(`/api/v1/settings/${id}`, data);
    return { success: true, data: response.data || {} };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { success: false, data: e?.response?.data || {} };
  }
};

export default updateSettings;
