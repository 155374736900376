import createUser, { UserInterface } from '@/entities/User';
import http, { captureException } from '@/services/http';

export const updateProfile = async (user: Partial<UserInterface>): Promise<any> => {
  try {
    const data = {
      name: user.name,
      phone: user.phone,
      telegramClientIds: user.telegramClientIds,
      webhookExecutionRequest: user.webhookExecutionRequest,
    };
    const response = await http().post('/api/v1/profile', data);
    return response?.data?.data ? createUser(response?.data?.data) : false;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default updateProfile;
