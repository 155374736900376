import http, { captureException } from '@/services/http';

export const getResultByPeriod = async (data: { start: string, end: string, userId?: string }): Promise<any> => {
  try {
    const start = new Date(data.start);
    start.setUTCHours(0, 0, 0, 0);

    const end = new Date(data.end);
    end.setUTCHours(23, 59, 59, 999);

    const params = {
      start: start.toISOString(),
      end: end.toISOString(),
      userId: data.userId || undefined,
    };
    const response = await http().get('/api/v1/reports/result', { params });
    return response?.data?.data || false;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default getResultByPeriod;
