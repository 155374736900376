import { GetterTree } from 'vuex';
import { TradeState } from './types';

const getters: GetterTree<TradeState, TradeState> = {
  getDefaultExecutionStatistics (state) {
    if (Object.keys(state.statistics.executions).length <= 0) {
      return false;
    }
    if (Object.keys(state.statistics.executions).length === 1) {
      const currency = Object.keys(state.statistics.executions)[0];
      return {
        ...state.statistics.executions[currency],
        currency,
      };
    }
    if (state.statistics.currency && state.statistics.executions[state.statistics.currency]) {
      return {
        ...state.statistics.executions[state.statistics.currency],
        currency: state.statistics.currency,
      };
    }
    return false;
  },

  lastExecution (state: TradeState) {
    const last = state.executions[0] || {};
    return `${last.id || '-'}:${last.status || '-'}`;
  },

  lastExecutionRequest (state: TradeState) {
    const last = state.executionRequests[0] || {};
    return `${last.id || '-'}:${last.status || '-'}`;
  },

  strategies () {
    return ['PA', 'RB', 'SA'];
  },
};

export default getters;
