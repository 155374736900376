








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgSettingsList from '@/components/Settings/SettingsList.vue';

const AuthStore = namespace('Auth');

@Component({
  components: { FgSettingsList },
})
export default class Settings extends Vue {
  protected loading = false;
  protected breadcrumbs: any;

  @AuthStore.Getter('isLogged') isLogged!: boolean;

  public beforeMount () {
    if (!this.isLogged) {
      this.$router.push({ name: 'Login' });
    }
    this.breadcrumbs = [
      {
        text: this.$t('menu.dashboard'),
        to: { name: 'Home' },
        exact: true,
        disabled: false,
      },
      {
        text: this.$t('menu.settings'),
        to: { name: 'Settings' },
        exact: true,
        disabled: false,
      },
    ];
  }
}
