












































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { currencyFilter } from '@/filters/currencyFilter';

const TradeStore = namespace('Trade');

@Component({
  filters: { currency: currencyFilter },
})
export default class ResultWidgets extends Vue {
  @TradeStore.Getter('getDefaultExecutionStatistics') statistics!: any;
  @TradeStore.Action('updateExecutionsCurrentStatistics') updateExecutionsCurrentStatistics!: CallableFunction;

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  protected async mounted () {
    await this.updateExecutionsCurrentStatistics();
  }
}
