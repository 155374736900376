




























































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgChangePassword from '@/components/Dashboard/ChangePassword.vue';
import FgMyProfile from '@/components/Users/MyProfile.vue';

const AuthStore = namespace('Auth');

@Component({ components: { FgChangePassword, FgMyProfile } })
export default class AppToolBar extends Vue {
  @AuthStore.State('user') user?: any;
  @AuthStore.Getter('isAdmin') isAdmin?: any;
  @AuthStore.Action('toogleShowSidebar') toogleShowSidebar!: CallableFunction;
  @AuthStore.Action('logout') logout!: CallableFunction;
  @AuthStore.Getter('isSimulation') isSimulation!: boolean;

  protected showChangePasswordModal = false;
  protected showMyProfileModal = false;

  get currentUser () {
    return this.user || {};
  }

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  protected toogleShowChangePasswordModal () {
    this.showChangePasswordModal = !this.showChangePasswordModal;
  }

  protected toogleShowMyProfileModal () {
    this.showMyProfileModal = !this.showMyProfileModal;
  }

  protected async sendLoggout () {
    try {
      await this.logout();
    } catch (e) {
      console.error(e);
    } finally {
      this.$router.push({ name: 'Login' });
    }
  }
}

