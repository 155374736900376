
































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgUserMenu from '@/components/Dashboard/UserMenu.vue';

const DashboardStore = namespace('Dashboard');
const AuthStore = namespace('Auth');

@Component({ components: { FgUserMenu } })
export default class Sidebar extends Vue {
  @AuthStore.Getter('isAdmin') isAdmin!: boolean;
  @AuthStore.Action('enabledFeature') enabledFeature!: CallableFunction;

  @DashboardStore.State('showSidebar') showSidebar!: boolean;
  @DashboardStore.State('showSidebarMinified') showSidebarMinified!: boolean;

  @DashboardStore.Action('changeTheme') changeTheme!: CallableFunction;
  @DashboardStore.Action('toogleShowSidebar') toogleShowSidebar!: CallableFunction;
  @DashboardStore.Action('toogleShowSidebarMinified') toogleShowSidebarMinified!: CallableFunction;

  protected menu = [
    {
      name: 'menu.dashboard',
      icon: '$dashboard',
      route: { name: 'Home' },
      exact: true,
    },
    {
      name: 'menu.executions',
      icon: '$executions',
      route: { name: 'Executions' },
      exact: true,
    },
    {
      name: 'menu.exchanges',
      icon: '$exchanges',
      route: { name: 'Exchanges' },
      exact: true,
    },
    {
      name: 'menu.settings',
      icon: '$settings',
      route: { name: 'Settings' },
      exact: true,
    },
    {
      name: 'menu.reports.title',
      icon: '$reports',
      exact: true,
      childrens: [
        {
          name: 'menu.reports.results',
          route: { name: 'ResultForPeriod' },
          icon: 'far fa-calendar-check',
          exact: true,
        },
      ],
    },
    {
      name: 'menu.eventLogs',
      icon: '$eventLogs',
      route: { name: 'EventLogs' },
      exact: true,
    },
  ];

  protected changeThemeAction (): void {
    const theme = this.$vuetify.theme.dark ? 'light' : 'dark';
    this.changeTheme(theme);
    this.$vuetify.theme.dark = theme === 'dark';
  }

  protected changeNavivationDrawer (v: boolean): void {
    if (this.showSidebar !== v) {
      this.toogleShowSidebar();
    }
  }

  protected async mounted () {
    if (this.isAdmin || await this.enabledFeature('execution-requests')) {
      this.menu.splice(1, 0, {
        name: 'menu.executionRequests',
        icon: '$executionRequests',
        route: { name: 'ExecutionRequests' },
        exact: true,
      });
    }

    if (this.isAdmin) {
      this.menu.push({
        name: 'menu.users',
        icon: '$users',
        route: { name: 'Users' },
        exact: true,
      });
    }
  }
}
