import { StoreOptions } from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { AuthState } from './types';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
} as StoreOptions<AuthState>;
