import { DashboardState } from './types';
import config from '@/config';

export const getState = (): DashboardState => ({
  app: {
    name: config.name,
    version: config.version,
    env: config.env,
    simulation: false,
  },
  theme: undefined,
  showSidebar: true,
  showSidebarMinified: false,
});

export default Object.assign(getState(), { getState });
