









import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgExecutionsList from '@/components/Executions/ExecutionsList.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgResultWidgets from '@/components/Dashboard/ResultWidgets.vue';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgExecutionsList, FgResultWidgets },
})
export default class Executions extends Vue {
  protected loading = false;
  protected breadcrumbs: any;

  @AuthStore.Getter('isLogged') isLogged!: boolean;

  public beforeMount () {
    if (!this.isLogged) {
      this.$router.push({ name: 'Login' });
    }
    this.breadcrumbs = [
      {
        text: this.$t('menu.dashboard'),
        to: { name: 'Home' },
        exact: true,
        disabled: false,
      },
      {
        text: this.$t('menu.executions'),
        to: { name: 'Executions' },
        exact: true,
        disabled: false,
      },
    ];
  }
}
