import createExchange, { ExchangeInterface } from '@/entities/Exchange';
import http, { captureException } from '@/services/http';

const createExchangeRequest = async (exchange: ExchangeInterface): Promise<ExchangeInterface | false> => {
  const data = {
    name: exchange.name,
    driver: exchange.driver,
    driverExchangeId: exchange.driverExchangeId,
    status: exchange.status,
    document: exchange.document || null,
    documentType: exchange.documentType || null,
    country: exchange.country || null,
    address: exchange.address || null,
    feeMaker: exchange.feeMaker,
    feeTaker: exchange.feeTaker,
    timeout: exchange.timeout || 10000,
    rateLimit: exchange.rateLimit || 0,
    apiUrl: exchange.apiUrl || null,
    wsUrl: exchange.wsUrl || null,
    apiKey: exchange.apiKey,
    apiSecret: exchange.apiSecret,
    options: exchange.options || {},
    proxy: exchange.proxy || null,

  };
  const response = await http().post('/api/v1/exchanges', data);
  if (!response?.data?.data) return false;

  return createExchange(response.data.data);
};

const updateExchangeRequest = async (exchange: ExchangeInterface): Promise<ExchangeInterface | false> => {
  const data = {
    name: exchange.name,
    status: exchange.status,
    document: exchange.document || null,
    documentType: exchange.documentType || null,
    country: exchange.country || null,
    address: exchange.address || null,
    feeMaker: exchange.feeMaker,
    feeTaker: exchange.feeTaker,
    options: exchange.options || {},
    proxy: exchange.proxy || null,
    timeout: exchange.timeout || 10000,
    rateLimit: exchange.rateLimit || 0,
  };
  const response = await http().put(`/api/v1/exchanges/${exchange.id}`, data);
  if (!response?.data?.data) return false;

  return createExchange(response.data.data);
};

export const saveExchange = async (exchange: ExchangeInterface): Promise<ExchangeInterface | false> => {
  try {
    if (exchange.id) {
      return updateExchangeRequest(exchange);
    }

    return createExchangeRequest(exchange);
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default saveExchange;
