





























































import { Vue, Component, Prop } from 'vue-property-decorator';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import cancelOrder from '@/services/Orders/cancelOrder';
import { OrderInterface } from '@/entities/Order';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { FgStatus, FgResumeTextField },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class OrdersList extends Vue {
  @Prop({ type: Array, required: true }) orders!: OrderInterface[];
  @Prop({ type: Boolean, required: false, default: false }) loading!: boolean[];

  protected headers: any = [];

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('orders.headers.id').toString(),
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: this.$t('orders.headers.status').toString(),
        align: 'center',
        sortable: false,
        value: 'status',
        width: '60px',
      },
      {
        text: this.$t('orders.headers.operation').toString(),
        align: 'start',
        sortable: false,
        value: 'operation',
      },
      {
        text: this.$t('orders.headers.exchange').toString(),
        align: 'start',
        sortable: false,
        value: 'exchange.name',
      },
      {
        text: this.$t('orders.headers.exchangeOrderId').toString(),
        align: 'start',
        sortable: false,
        value: 'exchangeOrderId',
      },
      {
        text: this.$t('orders.headers.market').toString(),
        align: 'start',
        sortable: false,
        value: 'market',
      },
      {
        text: this.$t('orders.headers.amount').toString(),
        align: 'start',
        sortable: false,
        value: 'amount',
      },
      {
        text: this.$t('orders.headers.price').toString(),
        align: 'start',
        sortable: false,
        value: 'price',
      },
      {
        text: this.$t('orders.headers.value').toString(),
        align: 'start',
        sortable: false,
        value: 'value',
      },
      {
        text: this.$t('orders.headers.fee').toString(),
        align: 'start',
        sortable: false,
        value: 'fee',
      },
      {
        text: this.$t('orders.headers.createdAt').toString(),
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
      {
        text: this.$t('orders.headers.netValue').toString(),
        align: 'end',
        sortable: false,
        value: 'finalEstimatedValue',
      },
      {
        text: this.$t('orders.headers.actions').toString(),
        align: 'end',
        sortable: false,
        value: 'actions',
      },
    ];
  }

  protected hasCancel (order: OrderInterface) {
    if (!['OPEN', 'ERROR'].includes(order.status || '')) return false;

    return true;
  }

  protected async executeCancelOrder (order: OrderInterface) {
    if (!order.id || !this.hasCancel(order)) return false;

    const response = await cancelOrder(order.id);
    if (!response.success) {
      this.$notify.error(
        this.$t('orders.actions.errorOnCancel.message'),
        this.$t('orders.actions.errorOnCancel.title'),
      );
      return;
    }
    order.status = 'CANCELLED';
    this.$notify.success(
      this.$t('orders.actions.successOnCancel.message'),
      this.$t('orders.actions.successOnCancel.title'),
    );
  }
}

