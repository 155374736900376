




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionDetails from '@/components/Executions/ExecutionDetails.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { FilterInterface } from '@/entities/FilteredOptions';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import PagedResource from '@/interfaces/PagedResource';
import { isValidUuid } from '@/validators/UuidValidator';
import { ExchangeInterface } from '../../entities/Exchange';

@Component({
  components: { FgWidget, FgStatus, FgExecutionDetails, FgResumeTextField },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExchangesListFilters extends Vue {
  @Prop({ type: Object, required: true }) resource!: PagedResource<ExchangeInterface>;
  @Prop({ type: Array, required: true }) headers!: any[];
  @Prop({ type: Boolean, required: false, default: false }) loading?: boolean;
  @Prop({ type: Array, required: true }) filters!: FilterInterface[];

  protected search = '';
  protected showFilters = false;
  protected statusFilter = [];
  protected searchHeader: any = {};
  protected oldSearchField?: FilterInterface;
  protected mutableFilters!: FilterInterface[];

  get avaliableFilters () {
    return this.headers.filter((h: any) => h.filterable);
  }

  get listStatus () {
    return [
      { name: 'ENABLED', icon: '$statusEnabled' },
      { name: 'DISABLED', icon: '$statusDisabled' },
    ];
  }

  get listStatusTranslated () {
    return this.listStatus.map((s: any) => ({ ...s, ...{ text: this.$t(`status.${s.name}`) } }));
  }

  get filterColor () {
    if (this.statusFilter.length > 0) {
      return 'error';
    }
    return 'primary';
  }

  get dateRules () {
    return [
      (value: string) => {
        const date = new Date(value);
        return date instanceof Date || 'Invalid Date';
      },
    ];
  }

  protected async clearSearchFilter () {
    this.search = '';
    this.changeFilters();
  }

  protected async clearStatusFilter () {
    this.statusFilter = [];
    this.changeFilters();
  }

  protected async changeSearchFilter () {
    if (!this.oldSearchField || (this.oldSearchField.field !== this.searchHeader.value || this.oldSearchField.value !== this.search)) {
      this.changeFilters();
    }
  }

  protected async changeHeaderFilter () {
    if (!this.search) {
      return;
    }
    this.changeFilters();
  }

  protected applyFilterBySearch () {
    if (!this.searchHeader || (!this.oldSearchField && !this.search)) {
      return;
    }
    if (!this.oldSearchField || (this.oldSearchField.field !== this.searchHeader.value || this.oldSearchField.value !== this.search)) {
      if (this.search) {
        this.oldSearchField = {
          field: this.searchHeader.value,
          operation: this.searchHeader.operation || '=',
          value: ['like', 'ilike'].includes(this.searchHeader.operation || '') ? `%${this.search || ''}%` : this.search || '',
        };
        if (this.oldSearchField.field === 'id' && !isValidUuid(this.search)) {
          return;
        }
        this.mutableFilters.push(this.oldSearchField);
      }
    }
  }

  protected applyFilterByStatus () {
    if (this.statusFilter.length > 0) {
      const statusFilterA = {
        field: 'status',
        operation: 'in',
        value: this.statusFilter.map((s: any) => s.name),
      };
      this.mutableFilters.push(statusFilterA);
    }
  }

  protected changeFilters () {
    this.mutableFilters = [];
    this.applyFilterBySearch();
    this.applyFilterByStatus();
    this.$emit('change', this.mutableFilters);
  }

  protected mounted () {
    this.mutableFilters = this.filters;
    if (!this.searchHeader?.value) {
      this.searchHeader = this.avaliableFilters[0] || {};
    }
  }
}

