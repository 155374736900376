import { MutationTree } from 'vuex';
import { ExecutionInterface } from '@/entities/Execution';
import { TradeState } from './types';

const limitSizeList = 30;
const limitEventSizeList = 80;

const mutations: MutationTree<TradeState> = {
  SET_EXECUTIONS (state, payload) {
    state.executions = payload;
  },

  ADD_EXECUTION (state, payload) {
    const newExecutions = [payload, ...state.executions];
    if (newExecutions.length > limitSizeList) {
      newExecutions.pop();
    }
    state.executions = newExecutions;
  },

  SET_EXECUTIONS_STATISTICS (state, payload) {
    state.statistics.executions = payload;
  },

  ADD_EXECUTION_CURRENT_STATISTICS (state, payload: ExecutionInterface) {
    const currency = payload.executedResultCurrency || undefined;
    if (!currency) return;

    if (!state.statistics.executions[currency]) {
      state.statistics.executions[currency] = {
        dayCount: 1,
        dayTotal: Number(payload.executedResult),
        weekCount: 1,
        weekTotal: Number(payload.executedResult),
        monthCount: 1,
        monthTotal: Number(payload.executedResult),
      };
    }

    state.statistics.executions[currency] = {
      dayCount: state.statistics.executions[currency].dayCount + 1,
      dayTotal: state.statistics.executions[currency].dayTotal + payload.executedResult,
      weekCount: state.statistics.executions[currency].weekCount + 1,
      weekTotal: state.statistics.executions[currency].weekTotal + payload.executedResult,
      monthCount: state.statistics.executions[currency].monthCount + 1,
      monthTotal: state.statistics.executions[currency].monthTotal + payload.executedResult,
    };
  },

  UPDATE_EXECUTION (state, payload) {
    let found = false;
    let newExecutions = state.executions.map((e) => {
      if (payload.id === e.id) {
        found = true;
        return payload;
      }
      return e;
    });
    if (!found) {
      newExecutions = [payload, ...state.executions];
      if (newExecutions.length > limitSizeList) {
        newExecutions.pop();
      }
    }
    state.executions = newExecutions;
  },

  SET_EXECUTION_REQUESTS (state, payload) {
    state.executionRequests = payload;
  },

  ADD_EXECUTION_REQUEST (state, payload) {
    const newExecutionRequests = [payload, ...state.executionRequests];
    if (newExecutionRequests.length > limitSizeList) {
      newExecutionRequests.pop();
    }
    state.executionRequests = newExecutionRequests;
  },

  UPDATE_EXECUTION_REQUEST (state, payload) {
    let found = false;
    let newExecutionRequests = state.executionRequests.map((e) => {
      if (payload.id === e.id) {
        found = true;
        return payload;
      }
      return e;
    });
    if (!found) {
      newExecutionRequests = [payload, ...state.executionRequests];
      if (newExecutionRequests.length > limitSizeList) {
        newExecutionRequests.pop();
      }
    }
    state.executionRequests = newExecutionRequests;
  },

  SET_OPPORTUNITIES (state, payload) {
    state.opportunities = payload;
  },

  ADD_OPPORTUNITY (state, payload) {
    const newOpportunities = [payload, ...state.opportunities];
    if (newOpportunities.length > limitSizeList) {
      newOpportunities.pop();
    }
    state.opportunities = newOpportunities;
  },

  UPDATE_OPPORTUNITY (state, payload) {
    let found = false;
    let newOpportunities = state.opportunities.map((e) => {
      if (payload.id === e.id) {
        found = true;
        return payload;
      }
      return e;
    });
    if (!found) {
      newOpportunities = [payload, ...state.opportunities];
      if (newOpportunities.length > limitSizeList) {
        newOpportunities.pop();
      }
    }

    state.opportunities = newOpportunities;
  },

  CLEAR_EVENTS (state) {
    state.events = [];
  },

  ADD_EVENT (state, payload) {
    payload.createdAt = new Date();
    const newEvents = [payload, ...state.events];
    if (newEvents.length > limitEventSizeList) {
      newEvents.pop();
    }
    state.events = newEvents;
  },

  CHANGE_REGISTER_EVENT (state, payload) {
    state.registerEvents = payload;
  },

  ADD_BEST_TICKERS (state, payload): void {
    if (state.bestTickers[payload.market]) {
      state.bestTickers[payload.market].push(payload);
      if (state.bestTickers[payload.market].length > 20) {
        state.bestTickers[payload.market].shift();
      }
      return;
    }
    state.bestTickers = { ...state.bestTickers, [payload.market]: [payload] };
  },

  SET_SETTINGS (state, payload) {
    state.settings = payload && Array.isArray(payload) ? payload : [];
  },

  SET_EXCHANGES (state, payload) {
    state.exchanges = payload && Array.isArray(payload) ? payload : [];
  },

  SET_EXCHANGE_DRIVERS (state, payload) {
    state.exchangeDrivers = payload && Array.isArray(payload) ? payload : [];
  },

  TOGGLE_NOTIFICATION_SOUND (state) {
    state.notificationSound = !state.notificationSound;
  },

  TOGGLE_NOTIFICATION_PENDING (state) {
    state.notificationPending = !state.notificationPending;
  },
};

export default mutations;
