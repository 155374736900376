





























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgTextField from '@/components/Core/TextField.vue';
import config from '@/config';

const AuthStore = namespace('Auth');

@Component({
  components: { FgTextField },
})
export default class Login extends Vue {
  protected showPassword = false;
  protected autofilled = false;
  protected loginMessage = '';
  protected loading = false;

  protected email = '';
  protected password = '';

  @AuthStore.Getter('isLogged') isLogged!: boolean;
  @AuthStore.Action('login') login!: CallableFunction;

  get appName (): string {
    return config.name || 'CriptoBot';
  }

  public beforeMount () {
    if (this.isLogged) {
      this.$router.push({ name: 'Home' });
    }
  }

  protected async sendLogin () {
    if (!this.email || !this.password || this.password.length < 4) {
      this.loginMessage = this.$t('login.errorEmptyForm').toString();
      return;
    }
    const data = { email: this.email, password: this.password };
    const { error } = await this.login(data);
    if (error) {
      this.loginMessage = error?.translate ? this.$t(error?.message).toString() : error?.message;
    }
    if (this.isLogged) {
      this.$router.push({ name: 'Home' });
    }
  }
}
