













































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import { timeFilter } from '@/filters/dateFilter';

const TradeStore = namespace('Trade');

@Component({ components: { FgWidget, FgStatus }, filters: { time: timeFilter } })
export default class EventsList extends Vue {
  @TradeStore.State('events') events?: any;
  @TradeStore.State('registerEvents') registerEvents?: boolean;
  @TradeStore.Action('toggleRegisterEvents') toggleRegisterEvents!: CallableFunction;

  protected headers: any = [];

  protected changeRegisterEvents () {
    this.toggleRegisterEvents(this.registerEvents);
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('events.headers.createdAt').toString(),
        align: 'start',
        sortable: false,
        value: 'createdAt',
        width: '115px',
      },
      {
        text: this.$t('events.headers.message').toString(),
        align: 'start',
        sortable: false,
        value: 'message',
      },
    ];
  }
}

