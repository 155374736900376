import { io, Socket } from 'socket.io-client';
import config from '@/config';
import createExecution from '@/entities/Execution';
import createExecutionRequest from '@/entities/ExecutionRequest';
import { currencyFilter } from '@/filters/currencyFilter';
import store from '@/store';

export default class SocketClient {
  protected socket?: Socket;
  protected token?: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor () {}

  public static initialize (token: string, supervisorId?: string): SocketClient | undefined {
    const client = new SocketClient();
    if (!token) return undefined;
    client.disconect();

    const options = {
      path: '/ws',
      transports: ['websocket'],
      auth: {
        token: token,
      },
      query: {},
      extraHeaders: {},
    };
    if (supervisorId) {
      options.query = {
        supervisor: supervisorId,
      };
    }

    client.socket = io(config.apiUrl, options);

    client.socket
      .on('connect', () => {
        console.log('Connected');
        client.dispatchEvent({}, 'Started Server Connection');
      })
      .on('balance', (message) => {
        switch (message.event) {
          case 'updated':
            client.dispatchEvent(message, `Balance updated for exchange ${message.data?.exchangeName || ''} [${message.data?.exchangeId || '?'}]`);
            break;
          default:
            client.dispatchEvent(message, `Balance ${message.event} notified`);
        }
      })
      .on('ticker', (message) => {
        if (message.event === 'updated') {
          store.dispatch('Trade/addBestTickers', { tickers: message.data, settingsId: message.settings?.id });
        }

        client.dispatchEvent(message, 'Tickers updated');
      })
      .on('opportunity', (message) => {
        switch (message.event) {
          case 'created':
          case 'found':
            store.dispatch('Trade/addOpportunity', message.data);
            client.dispatchEvent(message, `Opportunity ${message.data.id} created with expected gain ${message.data?.expectedResult || 0}`);
            break;
          case 'updated':
          case 'finished':
            const opF = message?.data?.opportunity || false;
            if (!opF) break;
            store.dispatch('Trade/updateOpportunity', message.data);
            break;
          case 'aborted':
            const opA = message?.data?.opportunity || false;
            if (!opA) break;
            store.dispatch('Trade/updateOpportunity', message.data);
            const ab = message?.data?.abort || {};
            const value = currencyFilter(ab.value || 0, ab.currency);
            const balance = currencyFilter(ab.balance || 0, ab.currency);
            client.dispatchEvent(message, `Aborted opportunity for ${ab.reason || '?'} on ${ab.exchange || '?'} ${value} ${ab.currency || '?'} (Balance: ${balance} ${ab.currency || '?'}) with gain ${opA?.expectedResult || 0} `);
            break;
          case 'not-found':
            client.dispatchEvent(message, 'Opportunity not found');
            break;
          default:
            client.dispatchEvent(message, `Opportunity ${message.event} notified for strategy `);
        }
      })
      .on('execution', (message) => {
        switch (message.event) {
          case 'created':
            store.dispatch('Trade/addExecution', createExecution(message.data));
            client.dispatchEvent(message, `Execution ${message.data.id} created`);
            break;
          case 'updated':
          case 'finished':
            const execution = createExecution(message.data);
            store.dispatch('Trade/updateExecution', execution);
            store.dispatch('Trade/addExecutionCurrentStatisticsValue', execution);
            const gainMsg = execution.strategy !== 'SA' ? `and gain ${execution?.executedResult || 0}` : '';
            client.dispatchEvent(message, `Execution ${execution.id} updated with status ${execution.status} ${gainMsg}`);
            store.dispatch('Trade/notify', 'executed-opportunity');
            break;
        }
      })
      .on('execution-request', (message) => {
        switch (message.event) {
          case 'created':
            store.dispatch('Trade/addExecutionRequest', createExecutionRequest(message.data));
            client.dispatchEvent(message, `Execution request ${message.data.id} created`);
            break;
          case 'updated':
          case 'finished':
            const request = createExecutionRequest(message.data);
            store.dispatch('Trade/updateExecutionRequest', request);
            client.dispatchEvent(message, `Execution request ${request.id} updated with status ${request.status || '?'}`);
            break;
        }
      })
      .on('exchange', (message) => {
        switch (message.event) {
          case 'error':
            client.dispatchEvent(message, `Error on exchange ${message?.data?.id || ''}: Locked`);
            break;
          default:
            client.dispatchEvent(message, `Exchange ${message.event} notified`);
        }
      })
      .on('connect_error', (e) => {
        console.log(e);
      });

    return client;
  }

  protected dispatchEvent (message: any, text: string) {
    if (store?.state?.Trade?.registerEvents) {
      store.dispatch('Trade/addEvent', {
        createdAt: message?.data?.createdAt || new Date(),
        settingsId: message?.data?.settingsId || message?.settings?.id || '',
        strategy: message?.data?.strategy || message?.settings?.strategy || '',
        message: text,
      });
    }
  }

  public disconect (): boolean {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.close();
      this.socket = undefined;
      console.log('Disconnected');
      this.dispatchEvent({ createdAt: new Date() }, 'Closed Server Connection');
      return true;
    }
    return false;
  }
}
