var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fg-widget',{staticClass:"global-balances-component",attrs:{"title":_vm.$t('exchanges.balances.global.title'),"icon":"$wallet","showMinized":false,"closeAction":_vm.close}},[_c('v-row',[_c('v-col',{staticClass:"pa-4",attrs:{"cols":"12","md":_vm.noChartData ? '12' : '8'}},[_c('v-checkbox',{staticClass:"pl-2 mt-0",attrs:{"x-small":"","hide-details":"auto","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('exchanges.balances.global.hideDisabledExchanges')))])]},proxy:true}]),model:{value:(_vm.hideDisabledExchanges),callback:function ($$v) {_vm.hideDisabledExchanges=$$v},expression:"hideDisabledExchanges"}}),_c('v-checkbox',{staticClass:"pl-2 mt-0",attrs:{"x-small":"","hide-details":"auto","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('exchanges.balances.global.hideLowBalances')))])]},proxy:true}]),model:{value:(_vm.hideLowBalances),callback:function ($$v) {_vm.hideLowBalances=$$v},expression:"hideLowBalances"}}),_c('v-data-table',{staticClass:"elevation-1 ma-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.precifiedBalances,"item-key":"name","hide-default-footer":"","items-per-page":30,"sort-by":"amount","sort-desc":true,"no-data-text":_vm.$t('dataTable.noDataText')},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFilter")(item.amount,item.currency))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFilter")(item.value,_vm.currency))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [(!_vm.isMobile)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" fas fa-question-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("dateTime")(item.updatedAt)))])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.updatedAt))+" ")])]}}],null,true)},[_c('template',{slot:"body.append"},[(_vm.isCellPhone)?_c('tr',{staticClass:"primary--text v-data-table__mobile-table-row"},[_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v("Total")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalBalance,_vm.currency)))])])]):_c('tr',{staticClass:"primary--text"},[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Total")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currencyFilter")(_vm.totalBalance,_vm.currency)))])])])],2)],1),(!_vm.noChartData)?_c('v-col',{staticClass:"pt-12",attrs:{"cols":"12","md":"4"}},[(!_vm.noChartData)?_c('fg-pie-chart',{staticClass:"pa-2",attrs:{"chart-data":_vm.datasource,"options":_vm.options,"height":210,"plugins":[]}}):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }