export interface ExchangeDriverInterface {
  id: string;
  driver: string;
  driverExchangeId: string;
  apiUrl?: string;
  status: string;
  name: string;
  logo: string;
  createdAt: Date;
  updatedAt: Date;
}

export const createExchangeDriver = (data: any): ExchangeDriverInterface => ({
  id: data.id || undefined,
  driver: data.driver || '',
  driverExchangeId: data.driverExchangeId || '',
  apiUrl: data.apiUrl || '',
  status: data.status || '',
  name: data.name || '',
  logo: data.logo || '',
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.createdAt),
});
