import createOrder, { OrderInterface } from './Order';

export interface ExecutionInterface {
  id?: string;
  userId?: string;
  opportunityId?: string;
  settingsId?: string;
  strategy?: string;
  status?: string;
  executedResult: number;
  executedResultCurrency?: string;
  executedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  orders: OrderInterface[],
}

export const createExecution = (data: any): ExecutionInterface => {
  const orders = (data?.orders || []).map((o: any) => createOrder(o));
  const executedResult = Number(data.executedResult || 0);
  const executedResultCurrency = data.executedResultCurrency || undefined;
  return {
    id: data.id || undefined,
    userId: data.userId || undefined,
    opportunityId: data.opportunityId || undefined,
    settingsId: data.settingsId || undefined,
    strategy: data.strategy || undefined,
    status: data.status || undefined,
    executedResult,
    executedResultCurrency,
    executedAt: data.executedAt ? new Date(data.executedAt) : undefined,
    createdAt: new Date(data.createdAt),
    updatedAt: new Date(data.updatedAt),
    orders,
  };
};

export default createExecution;
