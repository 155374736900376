import Currency from '@/entities/Currency';
import createExchange from '@/entities/Exchange';
import http, { captureException } from '@/services/http';

export interface GetExchangeByMarketOptions {
  currencyPrimary: Currency;
  currencySecondary: Currency;
  amount?: number;
}

export const getExchangesByMarket = async <T>(params: GetExchangeByMarketOptions, transformer?: CallableFunction): Promise<T[]> => {
  try {
    const response = await http().get('/api/v1/exchanges/market', { params });
    if (!response?.data?.data || !Array.isArray(response?.data?.data)) return [];

    return response.data.data.map((s: any) => transformer ? transformer(s) : createExchange(s));
  } catch (e) {
    captureException(e);
    console.error(e);
    return [];
  }
};

export default getExchangesByMarket;
