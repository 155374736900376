import Currency from './Currency';

export interface ExecutionRequestCheckerInterface {
  exchangeId: string;
  exchangeName?: string;
  currencyPrimary?: Currency;
  currencySecondary?: Currency;

  margin: number;
  amount: number;
  value: number;
  price: number;
  priceRequested: number;
  priceRequired: number;
  fee: number;
  feeCurrency: number;
  hasOpportunity: boolean;

}

export const createExecutionRequestChecker = (data: any): ExecutionRequestCheckerInterface => ({
  exchangeId: data.exchangeId || undefined,
  exchangeName: data.exchangeName || undefined,
  currencyPrimary: data.currencyPrimary || undefined,
  currencySecondary: data.currencySecondary || undefined,
  margin: Number(data.margin || 0),
  amount: Number(data.amount || 0),
  value: Number(data.value || 0),
  price: Number(data.price || 0),
  priceRequested: Number(data.priceRequested || 0),
  priceRequired: Number(data.priceRequired || 0),
  fee: Number(data.fee || 0),
  feeCurrency: data.feeCurrency || undefined,
  hasOpportunity: data.hasOpportunity || false,
});

export default createExecutionRequestChecker;
