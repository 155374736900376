



































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgTextField from '@/components/Core/TextField.vue';
import { UserInterface } from '../../entities/User';
import { dateTimeFilter } from '../../filters/dateFilter';
import updateProfile from '../../services/Users/updateProfile';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgTextField },
  filters: { dateTime: dateTimeFilter },
})
export default class MyProfile extends Vue {
  @AuthStore.State('user') user?: UserInterface;
  @AuthStore.Action('updateUser') updateUser!: CallableFunction;

  protected mutableUser: Partial<UserInterface> = {};

  protected loading = false;
  protected isValid = false;

  get currentUser () {
    return this.user || {};
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get requiredRules () {
    return [
      (value: string) => !!value || 'Required.',
    ];
  }

  get phoneRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => /^\+(?:[0-9]?){6,14}[0-9]$/.test(value) || this.$t('users.profile.fields.phoneHint'),
    ];
  }

  get isEnabledExecutionRequest () {
    return this.user?.features.includes('execution-requests');
  }

  protected validate () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.form.validate();
  }

  protected mounted () {
    this.mutableUser = Object.assign({}, this.user || {});
  }

  protected close () {
    this.$emit('close');
  }

  protected async handlerSave () {
    this.loading = true;
    try {
      const newUser = await updateProfile(this.mutableUser);
      if (!newUser) {
        throw new Error('Error on update profile');
      }

      await this.updateUser(newUser);
      this.loading = false;
      this.$notify.success('Save with success', this.$t('actions.success'));
      this.close();
    } catch (e) {
      this.$notify.error('Error on save', this.$t('actions.error'));
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @Watch('mutableUser.telegramClientIds')
  protected changeTelegramClientIds (val: string[]) {
    if (val.length > 3) {
      this.$nextTick(() => {
        if (this.mutableUser.telegramClientIds) {
          this.mutableUser.telegramClientIds.pop();
        }
      });
    }
  }
}
