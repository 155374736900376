
























































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgTextField from '@/components/Core/TextField.vue';
import changePassword from '@/services/Auth/changePassword';

const AuthStore = namespace('Auth');

@Component({ components: { FgWidget, FgTextField } })
export default class ChangePassword extends Vue {
  @AuthStore.State('user') user?: any;

  protected currentPassword = '';
  protected newPassword = '';
  protected confirmNewPassword = '';

  protected loading = false;
  protected isValid = false;
  protected showPassword = false;
  protected showNewPassword = false;
  protected showConfirmNewPassword = false;

  get currentUser () {
    return this.user || {};
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get passwordRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => value.length >= 6 || 'Required min 6 caracters',
    ];
  }

  get confirmNewPasswordRules () {
    const newPassword = this.newPassword;
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => value.length >= 6 || 'Required min 6 caracters',
      (value: string) => {
        if (newPassword !== value) return 'Invalid confirmation';
        return true;
      },
    ];
  }

  protected validate () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.form.validate();
  }

  protected async handlerChangePassword () {
    this.validate();
    if (!this.isValid) return false;

    this.loading = true;
    const response = await changePassword({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword,
    });

    this.loading = false;
    if (response.status !== 'success') {
      const message = response.translate ? this.$t(`users.changePassword.messages.${response.message}`) : response.message;
      this.$notify.error(message, this.$t('actions.error'));
      return;
    }

    this.$notify.success(this.$t('users.changePassword.messages.success'), this.$t('actions.success'));
    this.close();
  }

  protected close () {
    this.$emit('close');
  }
}
