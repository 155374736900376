import http, { captureException } from '@/services/http';

export const cancelExecutionRequest = async (requestId: string): Promise<{success: boolean, message?: string}> => {
  try {
    await http().patch(`/api/v1/execution-requests/${requestId}/cancel`);
    return { success: true };
  } catch (e) {
    captureException(e);
    console.error(e);

    return { success: false, message: e.response?.data?.message || e.message };
  }
};

export default cancelExecutionRequest;
