import { ActionTree } from 'vuex';
import { DashboardState } from './types';

const actions: ActionTree<DashboardState, DashboardState> = {
  changeTheme ({ commit }, payload) {
    commit('CHANGE_THEME', payload);
  },
  toogleShowSidebar ({ commit }) {
    commit('TOOGLE_SHOW_SIDEBAR');
  },
  toogleShowSidebarMinified ({ commit }) {
    commit('TOOGLE_SHOW_SIDEBAR_MINIFIED');
  },
};

export default actions;
