import { Currency, getPrecisionByCurrency } from '@/entities/Currency';

export const toString = (value: string | number, currency?: string | Currency, precision?: number) => {
  if (!value) {
    value = 0;
  }

  if (typeof value !== 'number') {
    value = Number(value);
  }

  if (typeof value !== 'number') {
    value = 0;
  }

  if (!precision) {
    precision = getPrecisionByCurrency(currency as Currency);
  }

  const optionsCurrency: any = { minimumFractionDigits: precision, maximumFractionDigits: precision };

  try {
    return value.toLocaleString('en', optionsCurrency);
  } catch (e) {
    return value;
  }
};

export default {
  toString,
};

