
import Chart from 'chart.js';
import { Line, mixins } from 'vue-chartjs';
import { Component, Prop, Mixins } from 'vue-property-decorator';

(Chart as any).defaults.global.defaultFontSize = 9;

@Component({ mixins: [mixins.reactiveProp] })
export default class LineChart extends Mixins(mixins.reactiveProp, Line) {
  @Prop({ type: Object, required: false, default: {} }) declare chartData: Chart.ChartData;
  @Prop({ type: Object, required: false, default: {} }) options!: Chart.ChartOptions;

  public mounted () {
    this.renderChart(this.chartData, this.options);
  }
}
