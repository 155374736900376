var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fg-widget',{staticClass:"executions-stream-component",attrs:{"title":_vm.$t('executions.stream.title'),"icon":"$executions","refresh":_vm.updateExecutionList,"show-maximized":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-2",staticStyle:{"height":"14px","font-size":"0.6em","font-weight":"bolder"},attrs:{"x-small":"","color":"primary","to":{ name: 'Executions' }}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"0.8em"},attrs:{"x-small":""}},[_vm._v("$executions")]),_vm._v(" "+_vm._s(_vm.$t('executions.actions.allExecutions'))+" ")],1)]},proxy:true}])},[_c('v-layout',{staticStyle:{"min-height":"100px","max-height":"230px"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.executions,"item-key":"id","hide-default-footer":"","items-per-page":30,"no-data-text":_vm.$t('dataTable.noDataText'),"loading":_vm.loading},on:{"dblclick:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('fg-status',{attrs:{"name":item.status,"description":_vm.$t(("status." + (item.status))),"icon":!_vm.isMobile}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("time")(item.createdAt))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.orders[0] ? item.orders[0].amount : 0))+" "+_vm._s(item.orders[0] ? item.orders[0].currencySecondary : '')+" ")]}},{key:"item.buy",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getBuyOrders(item.orders)),function(o){return _c('div',{key:o.id},[_c('fg-status',{attrs:{"name":o.status,"description":o.description ? o.description : _vm.$t(("status." + (o.status))),"icon":true}}),_vm._v(" "+_vm._s(o.exchange ? o.exchange.name : '?')+" "),_c('span',{staticClass:"caption"},[_vm._v("V:"+_vm._s(_vm._f("currency")(o.value,o.currencyPrimary))+" P:"+_vm._s(_vm._f("currency")(o.price,o.currencyPrimary)))])],1)})}},{key:"item.sell",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getSellOrders(item.orders)),function(o){return _c('div',{key:o.id},[_c('fg-status',{attrs:{"name":o.status,"description":o.description ? o.description : _vm.$t(("status." + (o.status))),"icon":true}}),_vm._v(" "+_vm._s(o.exchange ? o.exchange.name : '?')+" "),_c('span',{staticClass:"caption"},[_vm._v("V:"+_vm._s(_vm._f("currency")(o.value,o.currencyPrimary))+" P:"+_vm._s(_vm._f("currency")(o.price,o.currencyPrimary)))]),_vm._v(" "+_vm._s(o.currencyPrimary)+" ")],1)})}},{key:"item.executedResult",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"x-small":item.executedResult === 0,"color":_vm.getColorByItem(item)}},[_vm._v(_vm._s(_vm.getIconByItem(item))+" ")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.executedResult,item.orders[0] ? item.orders[0].currencyPrimary : ''))+" "+_vm._s(item.executedResultCurrency)+" ")],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"overlay-opacity":0.8},on:{"close":_vm.toggleExecutionDetails},model:{value:(_vm.showExecutionDetails),callback:function ($$v) {_vm.showExecutionDetails=$$v},expression:"showExecutionDetails"}},[(_vm.showExecutionDetails)?_c('fg-execution-details',{attrs:{"execution":_vm.currentExecution},on:{"close":_vm.toggleExecutionDetails}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }