import createUser from '@/entities/User';
import http, { captureException } from '@/services/http';

export const profile = async (): Promise<any> => {
  try {
    const response = await http().get('/api/v1/profile');
    return response?.data?.data ? createUser(response?.data?.data) : false;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default profile;
