var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"volume-for-currency-component"},[_c('h3',[_vm._v(_vm._s(_vm.$t('reports.results.volume.title')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.settings,"dense":"","item-key":"id","hide-default-footer":"","items-per-page":10000,"no-data-text":_vm.$t('dataTable.noDataText')},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.partialValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.partialCount)+"]")]),_vm._v(_vm._s(_vm._f("currency")(item.partialValue,item.currency))+" ")]}},{key:"item.finishedValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.finishedCount)+"]")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.finishedValue,item.currency))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.count)+"]")]),_vm._v(_vm._s(_vm._f("currency")(item.value,item.currency))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }