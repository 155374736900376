import { createBalance } from './Balance';

export interface ExchangeInterface {
  id?: string;
  userId?: string;
  name?: string;
  status?: string;
  statusDescription?: string;
  apiUrl?: string;
  wsUrl?: string;
  document?: string;
  documentType?: string;
  driver?: string;
  driverExchangeId?: string;
  feeMaker: number;
  feeTaker: number;
  options?: any;
  proxy?: string;
  timeout: number;
  rateLimit?: number | null;
  apiKey?: string;
  apiSecret?: string;
  address?: string;
  country?: string;
  createdAt: Date;
  updatedAt: Date;
  balances?: any[];
}

export const createExchange = (data: any = {}): ExchangeInterface => ({
  id: data?.id || undefined,
  userId: data.userId || undefined,
  name: data?.name || '',
  status: data?.status || 'ENABLED',
  statusDescription: data?.statusDescription || '',
  apiUrl: data?.apiUrl || '',
  wsUrl: data?.wsUrl || '',
  document: data?.document || '',
  documentType: data?.documentType || '',
  driver: data?.driver || '',
  driverExchangeId: data?.driverExchangeId || '',
  feeMaker: Number(data?.feeMaker || 0),
  feeTaker: Number(data?.feeTaker || 0),
  options: data?.options || {},
  proxy: data?.proxy || '',
  timeout: Number(data?.timeout || 0),
  rateLimit: data?.rateLimit ? Number(data.rateLimit) : undefined,

  address: data?.address || '',
  country: data?.country || '',
  createdAt: new Date(data?.createdAt),
  updatedAt: new Date(data?.createdAt),

  balances: (data?.balances || []).map((b: any) => createBalance(b)),
});

export default createExchange;
