import Vue from 'vue';
import Vuex from 'vuex';
import Dashboard from './modules/Dashboard';
import Trade from './modules/Trade';
import Auth from './modules/Auth';
import createPersistedState from 'vuex-persistedstate';
import config from '@/config';
import { RootState } from './types';

Vue.use(Vuex);
const modules: any = { Dashboard, Auth, Trade };

Object.keys(modules).forEach(key => {
  modules[key].mutations.CLEAR_STATE = (state: any) => {
    const defaultState = state.getState();
    Object.keys(state).forEach(key => {
      if (key !== 'getState') {
        state[key] = defaultState[key] || null;
      }
    });
  };
});

const storeKey = `${config.name}-v${config.version}`;
const store = new Vuex.Store<RootState>({
  modules,
  actions: {
    clearState: ({ commit }) => {
      try {
        Object.keys(modules).forEach(key => {
          commit(`${key}/CLEAR_STATE`, null, { root: false });
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  plugins: [
    createPersistedState({
      key: storeKey,
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
