import Currency from './Currency';
import createExecution, { ExecutionInterface } from './Execution';
import { OrderOperation } from './Order';

export interface NewExecutionRequestInterface {
  clientId?: string,
  operation: OrderOperation,
  currencyPrimary?: Currency;
  currencySecondary?: Currency;
  margin: number;
  amount: number;
  price: number;
}

export interface ExecutionRequestInterface {
  id?: string;
  userId?: string;
  status?: string;
  statusDescription?: string;
  statusSecondary?: string;
  operation?: OrderOperation;
  clientId?: string;
  exchanges: string[];
  currencyPrimary?: Currency;
  currencySecondary?: Currency;

  margin: number;
  amount: number;
  amountExecuted: number;
  price: number;
  priceExecuted: number;
  fee: number;

  description?: string;
  executedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  executions: ExecutionInterface[],
}

export const createExecutionRequest = (data: any): ExecutionRequestInterface => ({
  id: data.id || undefined,
  userId: data.userId || undefined,
  status: data.status || undefined,
  statusDescription: data.statusDescription || '',
  statusSecondary: data.statusSecondary || '',
  operation: data.operation || undefined,
  clientId: data.clientId || undefined,
  exchanges: data.exchanges || [],
  currencyPrimary: data.currencyPrimary || undefined,
  currencySecondary: data.currencySecondary || undefined,
  margin: Number(data.margin || 0),
  amount: Number(data.amount || 0),
  amountExecuted: Number(data.amountExecuted || 0),
  price: Number(data.price || 0),
  priceExecuted: Number(data.priceExecuted || 0),
  fee: Number(data.fee || 0),

  description: data.description || '',
  executedAt: data.executedAt ? new Date(data.executedAt) : undefined,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
  executions: (data?.executions || []).map((e: any) => createExecution(e)),
});

export default createExecutionRequest;
