var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fg-widget',{staticClass:"settings-list-component",attrs:{"title":_vm.$t('menu.settings'),"icon":"$settings","refresh":_vm.updateDatagrid}},[_c('fg-settings-list-filters',{attrs:{"resource":_vm.resource,"headers":_vm.headers,"loading":_vm.loading,"filters":_vm.filteredOptions.filters || []},on:{"change":_vm.changeFilters}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.settings,"item-key":"id","hide-default-footer":"","items-per-page":30,"no-data-text":_vm.$t('dataTable.noDataText'),"loading":_vm.loading},on:{"dblclick:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.userId}})]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.updatedAt))+" ")]}},{key:"item.strategy",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["+_vm._s(item.strategy)+"] "+_vm._s(_vm.$t(("strategies.names." + (item.strategy))))+" ")]}},{key:"item.strategyOptions",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getStrategyString(item.strategyOptions))}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function () { return _vm.editSettings(item); }}},[_c('v-icon',[_vm._v("$config")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('fg-change-status',{attrs:{"value":item.status,"loading":_vm.loading},on:{"change":function($event){return _vm.changeSettingsStatus(item)}}})]}}],null,true)}),_c('v-dialog',{attrs:{"overlay-opacity":0.8,"max-width":1000},on:{"close":_vm.toggleSettingsForm},model:{value:(_vm.showFormOptions),callback:function ($$v) {_vm.showFormOptions=$$v},expression:"showFormOptions"}},[(_vm.showFormOptions)?_c('fg-settings-form',{attrs:{"settings":_vm.currentSettings},on:{"close":_vm.closeEditForm}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }