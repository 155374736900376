import { ActionTree } from 'vuex';
import { AuthState } from './types';
import login from '@/services/Auth/login';
import logout from '@/services/Auth/logout';
import profile from '@/services/Users/profile';
import { enabledFeature as enabledFeatureHelper } from '../../../helpers/authHelper';

const actions: ActionTree<AuthState, AuthState> = {
  async login ({ commit }, user) {
    const response = await login(user.email, user.password);
    if (response.session) {
      commit('SET_SESSION', { token: response.session?.token, expiresAt: Date.now() + Number(response.session?.expiresIn || 0) });
      commit('SET_USER', response.session?.user);
      commit('SET_SIMULATION', response.session?.simulation || false);
    }
    return response;
  },

  async updateProfile ({ commit, state }, force = false) {
    if (!state.session) {
      return undefined;
    }

    if (state.user && !force) {
      return state.user;
    }

    const user = await profile();
    if (user) {
      commit('SET_USER', user);
    }
    return user;
  },

  updateUser ({ commit }, user) {
    commit('SET_USER', user);
  },

  enabledFeature ({ state }, feature: string) {
    return enabledFeatureHelper(state?.user || {} as any, feature);
  },

  async logout ({ dispatch }) {
    await logout();
    dispatch('clearSession');
    dispatch('clearState', null, { root: true });
  },

  clearSession ({ commit }) {
    commit('SET_SESSION', undefined);
    commit('SET_USER', undefined);
  },

  changeSupervisorUser ({ commit }, payload) {
    commit('SET_SUPERVISOR_USER', payload);
  },
};

export default actions;
