var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-list-component"},[_c('v-toolbar',{staticClass:"pr-2 d-print-none",attrs:{"height":"24","dark":"","color":"topBar"}},[_c('v-icon',{staticClass:"icon-title"},[_vm._v("$executions")]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('orders.title')))])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.orders,"item-key":"id","hide-default-footer":"","items-per-page":_vm.orders.length,"no-data-text":_vm.$t('dataTable.noDataText'),"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('fg-status',{attrs:{"name":item.status,"description":item.description ? item.description : _vm.$t(("status." + (item.status))),"icon":false}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,item.currencySecondary))+" "+_vm._s(item.currencySecondary)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,item.currencyPrimary))+" "+_vm._s(item.currencyPrimary)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value,item.currencyPrimary))+" "+_vm._s(item.currencyPrimary)+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.fee,item.feeCurrency))+" "+_vm._s(item.feeCurrency)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt))+" ")]}},{key:"item.finalEstimatedValue",fn:function(ref){
var item = ref.item;
return [(item.currencyPrimary !== item.feeCurrency && item.currencySecondary !== item.feeCurrency)?_c('span',{staticClass:"info--text",staticStyle:{"font-size":"1.2em"}},[_vm._v("≅")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("currency")(item.netValue,item.currencyPrimary))+" "+_vm._s(item.currencyPrimary)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasCancel(item))?_c('v-btn',{staticClass:"error",attrs:{"x-small":""},on:{"click":function () { return _vm.executeCancelOrder(item); }}},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("$cancel")]),_vm._v(" "+_vm._s(_vm.$t("orders.cancelOrder"))+" ")],1):_c('span',[_vm._v("-")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }