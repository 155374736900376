export default {
  menu: {
    title: 'Menu',
    dashboard: 'Dashboard',
    settings: 'Settings',
    exchanges: 'Exchanges',
    executions: 'Executions',
    executionRequests: 'Requests',
    eventLogs: 'Event Logs',
    reports: {
      title: 'Reports',
      results: 'Result for Period',
    },
    users: 'Users',
    user: {
      myProfile: 'My Profile',
      changePassword: 'Change Password',
      logout: 'Logout',
    },
  },
  datatable: {
    filter: 'Filter',
    search: 'Search',
    statusFilter: 'Status Filter',
  },
  actions: {
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    success: 'Success',
    error: 'Error',
    save: 'Save',
    clear: 'Clear',
  },
  dataTable: {
    noDataText: 'No data available',
    rowsPerPage: 'Rows per page',
  },
  simulationMode: 'Simulation Mode',
  status: {
    OPEN: 'Open',
    PENDING: 'Pending',
    PROCESSING: 'Processing',
    ERROR: 'Error',
    PARTIAL: 'Partial',
    CANCELLED: 'Cancelled',
    FINISHED: 'Finished',
    undefined: 'Undefined',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
  dashboard: {},
  login: {
    email: 'Email',
    password: 'Password',
    submit: 'Login',
    error: 'Could not sign in',
    errorEmptyForm: 'You must enter a valid email and password',
    tooManyRequests: 'Too many attempts were made, please try again later!',
  },
  executions: {
    title: 'Executions',
    headers: {
      id: 'Id',
      userId: 'User Id',
      status: 'Status',
      createdAt: 'Date',
      strategy: 'Strategy',
      amount: 'Amount',
      buy: 'Buy',
      sell: 'Sell',
      executedResult: 'Result',
      executedAt: 'Executed At',
    },
    details: {
      title: 'Execution Detail',
    },
    filters: {
      startDate: 'Start Date',
      endDate: 'End Date',
    },
    stream: {
      title: 'Last Executions',
    },
    actions: {
      allExecutions: 'All Executions',
    },
  },
  executionRequests: {
    title: 'Execution Requests',
    headers: {
      id: 'Id',
      userId: 'User Id',
      clientId: 'Client Id',
      status: 'Status',
      createdAt: 'Date',
      market: 'Market',
      margin: 'Margin',
      amount: 'Amount Requested',
      amountExecuted: 'Amount Executed',
      price: 'Price Requested',
      priceExecuted: 'Price Executed',
      fee: 'Fee',
      executedAt: 'Executed At',
      exchanges: 'Exchanges',
      operation: 'Operation',
      actions: 'Actions',
    },
    details: {
      title: 'Execution Request Detail',
    },
    filters: {
      startDate: 'Start Date',
      endDate: 'End Date',
    },
    all: 'All',
    actions: {
      unlock: {
        action: 'Unlock',
        error: 'Error on unlocked execution request',
        success: 'Success on unlocked execution request',
      },
      retry: {
        action: 'Retry',
        error: 'Error on retry execution request',
        success: 'Success on retry execution request',
      },
      newMobile: 'New',
      new: 'New Request',
      successOnCancel: {
        message: 'Execution request cancelled with success',
        title: 'Success',
      },

      errorOnCancel: {
        message: 'Error on cancel execution request',
        title: 'Error',
      },

      successOnSave: {
        message: 'Execution request created with success',
        title: 'Success',
      },

      errorOnSave: {
        message: 'Error on create execution request',
        title: 'Error',
      },
    },
    form: {
      title: 'New Exchange Request',
      currencyPrimary: 'Currency Primary',
      currencySecondary: 'Currency Secondary',
      exchanges: 'Exchanges (Empty for All)',
      margin: 'Margin',
      amount: 'Amount',
      price: 'Price',
      clientId: 'Client Id',
      buy: 'Send Buy Order',
      sell: 'Send Sell Order',
    },
    checkers: {
      title: 'Current Check Status',
      headers: {
        exchangeId: 'Exchange Id',
        exchangeName: 'Exchange',
        market: 'Market',
        margin: 'Margin',
        amount: 'Amount',
        value: 'Value',
        price: 'Price',
        priceRequested: 'Price Requested',
        priceRequired: 'Price Required',
        fee: 'Fee',
        hasOpportunity: 'Opportunity',
      },
      showCheckers: 'Get Checkers for Execution Request',
      found: 'Found',
      notFound: 'Not Found',
    },
  },
  events: {
    title: 'Last Events',
    headers: {
      createdAt: 'Date',
      message: 'Message',
    },
    actions: {
      stop: 'Stop',
      restart: 'Restart',
    },
  },
  tickers: {
    priceMonitor: {
      title: 'Price Monitor',
      selectMarket: 'Market',
      margin: 'Margin',
      market: 'Market',
      labels: {
        bestSell: 'Best Sell',
        bestBuy: 'Best Buy',
        margin: 'Buy with Margin',
      },
    },
  },
  strategies: {
    names: {
      PA: 'Positioned Arbitrage',
      RB: 'Rebalance',
      SA: 'Standalone',
    },
  },
  exchanges: {
    headers: {
      id: 'Id',
      userId: 'User Id',
      updatedAt: 'Updated At',
      name: 'Name',
      driverExchangeId: 'Exchange',
      balances: 'Balances',
      status: 'Status',
      feeTaker: 'Fee Taker',
      feeMaker: 'Fee Maker',
      keys: 'Keys',
    },
    actions: {
      newMobile: 'New',
      new: 'New Exchange',
    },
    form: {
      title: 'Exchange',
      cancel: 'Cancel',
      save: 'Save',
      fields: {
        name: 'Name',
        feeTaker: 'Fee Taker',
        feeMaker: 'Fee Maker',
        document: 'Document',
        documentType: 'Domument Type',
        statusEnabled: 'Enabled',
        statusDisabled: 'Disabled',
        address: 'Address',
        country: 'Country',
        exchangeDriver: 'Exchange Driver',
        key: 'Key',
        secret: 'Secret',
        additionalTitle: 'Additional Data',
        apiKey: 'Api Key',
        apiSecret: 'Api Secret',
      },
    },
    balances: {
      global: {
        title: 'Global Balance',
        hideLowBalances: 'Hide low balances',
        hideDisabledExchanges: 'Hide disabled exchanges',
      },
    },
  },
  exchangeBalances: {
    title: 'Balances',
    headers: {
      currency: 'Currency',
      amount: 'Amount',
      value: 'Value',
      updatedAt: 'Updated At',
    },
    forceUpdate: 'Force Update',
    successUpdate: 'Success on update balance',
    errorUpdate: 'Error on update balance',
  },
  settings: {
    headers: {
      id: 'Id',
      userId: 'User Id',
      name: 'Name',
      strategy: 'Strategy',
      updatedAt: 'Updated At',
      status: 'Status',
      strategyOptions: 'Options',
      action: 'Settings',
    },
    options: {
      margin: 'Margin',
      minGain: 'Min Gain',
      pair: 'Pair',
      rebalance: 'Rebalance',
      lotSize: 'Lot',
    },
    form: {
      lotSize: 'Lot Size',
      margin: 'Margin',
      minGain: 'Min Gain',
      strategy: 'Strategy',
      currencyPrimary: 'Primary Currency',
      currencySecondary: 'Secondary Currency',
      rebalance: {
        enabled: 'Rebalance',
        minRebalanceGain: 'Min Gain',
        primaryMinRatio: 'Primary Min Ratio',
        secondaryMinRatio: 'Secondary Min Ratio',
      },
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  orders: {
    title: 'Orders',
    headers: {
      id: 'Id',
      status: 'Status',
      operation: 'Op.',
      exchange: 'Exchange',
      exchangeOrderId: 'Order Id',
      market: 'Market',
      amount: 'Amount',
      price: 'Price',
      value: 'Value',
      fee: 'Fee',
      createdAt: 'Date',
      actions: 'Actions',
      netValue: 'Net Value ',
    },
    cancelOrder: 'Cancel',
    actions: {
      successOnCancel: {
        message: 'Order cancelled with success',
        title: 'Success',
      },

      errorOnCancel: {
        message: 'Error on cancel order',
        title: 'Error',
      },
    },
  },
  reports: {
    results: {
      title: 'Result for Period',
      filters: {
        userId: 'User Id',
        startDate: 'Start Date',
        endDate: 'End Date',
        generate: 'Generate',
      },
      currency: {
        title: 'Result for Currency',
        headers: {
          currency: 'Currency',
          gain: 'Gain',
          loss: 'Loss',
          result: 'Result',
        },
      },
      settings: {
        title: 'Result for Settings',
        headers: {
          id: 'Id',
          name: 'Name',
          currency: 'Currency',
          gain: 'Gain',
          loss: 'Loss',
          result: 'Result',
        },
      },
      volume: {
        title: 'Volume for Currency',
        headers: {
          currency: 'Currency',
          partial: 'Partial',
          finished: 'Finished',
          value: 'Total',
        },
      },
      exchange: {
        title: 'Volume for Exchange',
        headers: {
          name: 'Exchange',
          currency: 'Currency',
          partial: 'Partial',
          finished: 'Finished',
          value: 'Total',
        },
      },
    },
  },
  users: {
    title: 'Users',
    headers: {
      id: 'Id',
      createdAt: 'Created At',
      email: 'Email',
      name: 'Name',
      roles: 'Roles',
      isEnabled2fa: 'Enabled 2FA',
      status: 'Status',
    },
    changePassword: {
      title: 'Change Password',
      email: 'Email',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      messages: {
        success: 'Success on change password',
        error: 'Error on change password',
        currentPassword: 'Invalid current password',
        newPassword: 'Invalid new Password',
        confirmNewPassword: 'Invalid confirm new password',
      },
    },
    profile: {
      title: 'My Profile',
      fields: {
        email: 'Email',
        phone: 'Phone',
        phoneHint: 'Use E.164 international phone number format',
        name: 'Name',
        telegramClientIds: 'Telegram Client Ids',
        maxTelegramClientIds: 'Maximum of 3 client ids',
        webhookExecutionRequest: 'Webhook for executions request',
        isEnabled2fa: 'Enabled 2FA',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        isEnabledExecutionRequest: 'Enabled Execution Request',
      },
    },
  },
  eventLogs: {
    headers: {
      createdAt: 'Created At',
      id: 'Id',
      userId: 'User Id',
      level: 'Level',
      origin: 'Origin',
      event: 'Event',
      message: 'Message',
    },
    filters: {
      origin: 'Origin',
    },
    origins: {
      unknown: 'Unknown',
      auth: 'Auth',
      exchanges: 'Exchanges',
      notifications: 'Notifications',
      settings: 'Settings',
      users: 'Users',
      websockets: 'Websockets',
    },
  },
  version: {
    text: 'There is a new version of the application available!',
    refresh: 'Update',
  },
  supervisor: {
    title: 'Supervisor User',
    fields: {
      userId: 'User Id',
    },
    form: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },
};
