import http, { captureException } from '@/services/http';

export const cancelOrder = async (orderId: string): Promise<{success: boolean, message?: string}> => {
  try {
    await http().patch(`/api/v1/orders/${orderId}/cancel`);
    return { success: true };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { success: false, message: e.message };
  }
};

export default cancelOrder;
