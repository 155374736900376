














































import { Vue, Component, Prop } from 'vue-property-decorator';
import FgWidget from '@/components/Dashboard/Widget.vue';
import saveExchangeKeys from '@/services/Exchanges/saveExchangeKeys';
import { dateTimeFilter } from '@/filters/dateFilter';

@Component({
  components: { FgWidget },
  filters: { dateTime: dateTimeFilter },
})
export default class ExchangeKeysForm extends Vue {
  @Prop({ type: String, required: false }) readonly id!: string;
  @Prop({ type: String, required: false }) readonly name!: string;

  protected loading = false;
  protected apiKey = '';
  protected apiSecret = '';

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get keyRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => (value && value.length >= 3) || 'Min 3 characters',
    ];
  }

  protected close (params = {}) {
    this.$emit('close', params);
  }

  protected async save () {
    const savedExchange = await saveExchangeKeys(this.id, this.apiKey, this.apiSecret);
    if (savedExchange) {
      this.$notify.success('Save with success', this.$t('actions.success'));
      return this.close({ refresh: true });
    }

    this.$notify.error('Error on save', this.$t('actions.error'));
  }
}

