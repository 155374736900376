






















































import { Vue, Component, Prop } from 'vue-property-decorator';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { ExecutionRequestInterface } from '@/entities/ExecutionRequest';
import getExecutionRequestCheckers from '@/services/ExecutionRequests/getExecutionRequestCheckers';
import PagedResource from '@/interfaces/PagedResource';
import { createPagedMeta } from '@/entities/PagedMeta';
import { ExecutionRequestCheckerInterface } from '@/entities/ExecutionRequestChecker';

@Component({
  components: { FgStatus, FgResumeTextField },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class CheckersList extends Vue {
  @Prop({ type: Object, required: true }) request!: ExecutionRequestInterface;

  protected resource: PagedResource<ExecutionRequestCheckerInterface> = { data: [], meta: createPagedMeta() };
  protected headers: any = [];
  protected loading = false;

  get checkers () {
    if (!this.resource?.data) return [];

    return this.resource.data;
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('executionRequests.checkers.headers.exchangeId').toString(),
        align: 'start',
        sortable: false,
        value: 'exchangeId',
      },
      {
        text: this.$t('executionRequests.checkers.headers.exchangeName').toString(),
        align: 'center',
        sortable: false,
        value: 'exchangeName',
      },
      {
        text: this.$t('executionRequests.checkers.headers.market').toString(),
        align: 'start',
        sortable: false,
        value: 'market',
      },
      {
        text: this.$t('executionRequests.checkers.headers.amount').toString(),
        align: 'start',
        sortable: false,
        value: 'amount',
      },
      {
        text: this.$t('executionRequests.checkers.headers.price').toString(),
        align: 'start',
        sortable: false,
        value: 'price',
      },
      {
        text: this.$t('executionRequests.checkers.headers.priceRequested').toString(),
        align: 'start',
        sortable: false,
        value: 'priceRequested',
      },
      {
        text: this.$t('executionRequests.checkers.headers.priceRequired').toString(),
        align: 'start',
        sortable: false,
        value: 'priceRequired',
      },
      {
        text: this.$t('executionRequests.checkers.headers.value').toString(),
        align: 'start',
        sortable: false,
        value: 'value',
      },
      {
        text: this.$t('executionRequests.checkers.headers.fee').toString(),
        align: 'start',
        sortable: false,
        value: 'fee',
      },
      {
        text: this.$t('executionRequests.checkers.headers.hasOpportunity').toString(),
        align: 'end',
        sortable: false,
        value: 'hasOpportunity',
      },
    ];
  }

  protected async updateCheckers () {
    if (!this.request?.id) return false;
    this.loading = true;
    try {
      this.resource = await getExecutionRequestCheckers(this.request.id);
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  protected async mounted () {
    await this.updateCheckers();
  }
}

