































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import FgChangeStatus from '@/components/Core/ChangeStatus.vue';
import FgExchangeBalances from '@/components/Exchanges/ExchangeBalances.vue';
import FgExchangesListFilters from '@/components/Exchanges/ExchangesListFilters.vue';
import FgGlobalBalances from '@/components/Exchanges/GlobalBalances.vue';
import FgExchangeForm from '@/components/Exchanges/ExchangeForm.vue';
import FgExchangeKeysForm from '@/components/Exchanges/ExchangeKeysForm.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import createExchange, { ExchangeInterface } from '@/entities/Exchange';
import enableExchange from '@/services/Exchanges/enableExchange';
import disableExchange from '@/services/Exchanges/disableExchange';
import { BalanceInterface } from '@/entities/Balance';
import FilteredOptionsInterface, { FilterInterface } from '@/entities/FilteredOptions';
import { createOptionsPage } from '@/entities/OptionsPaged';
import PagedResource from '@/interfaces/PagedResource';
import getExchanges from '@/services/Exchanges/getExchanges';
import { createPagedMeta } from '@/entities/PagedMeta';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgStatus, FgExchangesListFilters, FgResumeTextField, FgChangeStatus, FgExchangeBalances, FgGlobalBalances, FgExchangeForm, FgExchangeKeysForm },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class AppToolBar extends Vue {
  @AuthStore.Getter('isInternal') isInternal?: boolean;

  protected resource: PagedResource<ExchangeInterface> = { data: [], meta: createPagedMeta() };
  protected options = createOptionsPage(this.resource?.meta);
  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  protected headers: any[] = [];
  protected loading = false;
  protected showModalBalance = false;
  protected showModalGlobalBalance = false;
  protected showExchangeForm = false;
  protected showExchangeKeysForm = false;
  protected balances: BalanceInterface[] = [];
  protected currentExchange?: ExchangeInterface = undefined;

  get exchanges () {
    return this.resource?.data || [];
  }

  get isMobile () {
    return this.$vuetify.breakpoint.xs;
  }

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('exchanges.headers.id').toString(),
        align: 'start',
        sortable: true,
        value: 'id',
        width: '80px',
        filterable: true,
      },
      {
        text: this.$t('exchanges.headers.updatedAt').toString(),
        align: 'start',
        sortable: true,
        value: 'updatedAt',
      },
      {
        text: this.$t('exchanges.headers.name').toString(),
        align: 'start',
        sortable: true,
        value: 'name',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('exchanges.headers.driverExchangeId').toString(),
        align: 'start',
        sortable: false,
        value: 'driverExchangeId',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('exchanges.headers.feeTaker').toString(),
        align: 'start',
        sortable: false,
        value: 'feeTaker',
      },
      {
        text: this.$t('exchanges.headers.feeMaker').toString(),
        align: 'start',
        sortable: false,
        value: 'feeMaker',
      },
      {
        text: this.$t('exchanges.headers.keys').toString(),
        align: 'center',
        sortable: false,
        value: 'keys',
        width: '80px',
      },
      {
        text: this.$t('exchanges.headers.balances').toString(),
        align: 'center',
        sortable: false,
        value: 'balances',
        width: '60px',
      },
      {
        text: this.$t('exchanges.headers.status').toString(),
        align: 'center',
        sortable: true,
        value: 'status',
        width: '60px',
      },
    ];
    if (this.isInternal) {
      this.headers.splice(1, 0, {
        text: this.$t('exchanges.headers.userId').toString(),
        align: 'start',
        sortable: true,
        filterable: true,
        value: 'userId',
        width: '80px',
      });
    }
  }

  protected async updateDatagrid () {
    try {
      this.loading = true;
      try {
        this.resource = await getExchanges({
          ...this.filteredOptions,
          populate: ['balances'],
        });
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  public mounted () {
    this.updateDatagrid();
  }

  protected async closeExchangeForm (data: any = {}) {
    this.toggleExchangeForm();
    if (data?.refresh) {
      await this.updateDatagrid();
    }
  }

  protected async closeExchangeKeysForm (data: any = {}) {
    this.toggleExchangeKeysForm();
    if (data?.refresh) {
      await this.updateDatagrid();
    }
  }

  protected async closeModalBalance (data: any = {}) {
    this.toggleModalBalance();
    if (data?.refresh) {
      await this.updateDatagrid();
    }
  }

  protected showBalances (item: any) {
    this.balances = item?.balances || [];
    this.currentExchange = item;
    this.showModalBalance = true;
  }

  protected changeKeys (item: any) {
    this.currentExchange = item;
    this.toggleExchangeKeysForm();
  }

  protected toggleModalBalance () {
    this.showModalBalance = !this.showModalBalance;
  }

  protected toggleModalGlobalBalance () {
    this.showModalGlobalBalance = !this.showModalGlobalBalance;
  }

  protected async changeExchangeStatus (exchange: ExchangeInterface) {
    if (this.loading || !exchange || !exchange.id) return false;
    this.loading = true;

    await ((exchange.status === 'ENABLED') ? disableExchange(exchange.id) : enableExchange(exchange.id));
    await this.updateDatagrid();
    this.loading = false;
  }

  protected toggleExchangeForm () {
    this.showExchangeForm = !this.showExchangeForm;
    if (!this.showExchangeForm) {
      this.currentExchange = undefined;
    }
  }

  protected toggleExchangeKeysForm () {
    this.showExchangeKeysForm = !this.showExchangeKeysForm;
    if (!this.showExchangeKeysForm) {
      this.currentExchange = undefined;
    }
  }

  protected rowClicked (_: any, row: any) {
    this.currentExchange = row.item;
    this.toggleExchangeForm();
  }

  protected newExchange () {
    this.currentExchange = createExchange({});
    this.toggleExchangeForm();
  }
}

