import { Store } from 'vuex';
import { RootState } from '@/store/types';

export interface PermissionRestriction {
  roles? : string[],
}

const authorizer = async ({ getters }: Store<RootState>, _: string, permissions: PermissionRestriction) => {
  if (!getters['Auth/isLogged']) {
    return false;
  }

  if (permissions && permissions.roles) {
    const user = getters['Auth/currentUser'];
    if (!user || !user.roles) {
      return false;
    }

    return !!user.roles.find((r: string) => permissions?.roles?.includes(r));
  }

  return true;
};

export default authorizer;
