import createExchange, { ExchangeInterface } from '@/entities/Exchange';
import http, { captureException } from '@/services/http';

export const disableExchange = async (id: string, params: any = {}): Promise<ExchangeInterface | false> => {
  try {
    const response = await http().patch(`/api/v1/exchanges/${id}/update-balance`, { params });
    if (!response?.data?.data) return false;

    return createExchange(response.data.data);
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default disableExchange;
