

































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Widget extends Vue {
  @Prop({ type: String, required: false }) readonly title?: string;
  @Prop({ type: String, required: false, default: 'topBar' }) readonly titleColor?: string;
  @Prop({ type: String, required: false }) readonly icon?: string;
  @Prop({ type: Function, required: false }) readonly refresh?: CallableFunction;
  @Prop({ type: Boolean, required: false, default: true }) readonly showMinized?: boolean;
  @Prop({ type: Boolean, required: false, default: true }) readonly showMaximized?: boolean;
  @Prop({ type: Boolean, required: false, default: false }) readonly minify?: boolean;
  @Prop({ type: Boolean, required: false, default: true }) readonly showHeader?: boolean;
  @Prop({ type: Function, required: false, default: null }) readonly closeAction?: boolean;

  protected minified = false;
  protected maximized = false;
  protected loading = false;

  protected beforeMount () {
    this.minified = this.minify || false;
  }

  protected get showClose () {
    return (typeof this.closeAction === 'function');
  }

  protected async refreshAction () {
    if (this.refresh) {
      this.loading = true;
      await this.refresh();
      this.loading = false;
    }
  }

  protected async minifyAction () {
    this.minified = !this.minified;
    this.$emit('minified', this.minified);
  }

  protected async maximizeAction () {
    this.maximized = !this.maximized;
    this.$emit('maximized', this.maximized);
  }
}
