import http, { captureException } from '@/services/http';

export const saveExchangeKeys = async (exchangeId: string, apiKey: string, apiSecret?: string): Promise<boolean> => {
  try {
    await http().put(`/api/v1/exchanges/${exchangeId}/change-keys`, { apiKey, apiSecret });

    return true;
  } catch (e) {
    captureException(e);
    console.error(e);
    return false;
  }
};

export default saveExchangeKeys;
