



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgUsersDataTable from '@/components/Users/UsersDataTable.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import getUsers from '@/services/Users/getUsers';
import enableUser from '@/services/Users/enableUser';
import disableUser from '@/services/Users/disableUser';
import { createOptionsPage } from '@/entities/OptionsPaged';
import FilteredOptionsInterface, { FilterInterface } from '@/entities/FilteredOptions';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import PagedResource from '@/interfaces/PagedResource';
import { createPagedMeta } from '@/entities/PagedMeta';
import { UserInterface } from '@/entities/User';

const TradeStore = namespace('Trade');

@Component({
  components: { FgWidget, FgStatus, FgUsersDataTable, FgResumeTextField },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class UsersList extends Vue {
  protected loading = false;

  protected resource: PagedResource<UserInterface> = { data: [], meta: createPagedMeta() };
  protected showExecutionDetails = false;
  protected currentUser?: UserInterface;
  protected options = createOptionsPage(this.resource?.meta);

  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  @TradeStore.Getter('lastExecution') lastExecution!: string;

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  protected async mounted () {
    await this.updateDatagrid();
  }

  protected async updateDatagrid () {
    this.loading = true;
    try {
      this.resource = await getUsers({
        ...this.filteredOptions,
        populate: [],
      });
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  protected toggleExecutionDetails () {
    this.showExecutionDetails = !this.showExecutionDetails;
  }

  protected rowClicked (row: any) {
    this.currentUser = row.item;
    this.toggleExecutionDetails();
  }

  protected async changeOptions (value: any) {
    value.limit = value.itemsPerPage;
    this.filteredOptions.options = value;
    await this.updateDatagrid();
  }

  protected async changeUserStatus (user: UserInterface) {
    if (this.loading || !user) return false;
    this.loading = true;

    await ((user.status === 'ENABLED') ? disableUser(user.id) : enableUser(user.id));
    await this.updateDatagrid();
    this.loading = false;
  }

  @Watch('lastExecution')
  protected async updatedExecutionList () {
    if (this.resource?.meta?.page === 1) {
      await this.updateDatagrid();
    }
  }
}

