var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"execution-request-checker-list-component"},[_c('v-toolbar',{staticClass:"pr-2 d-print-none",attrs:{"height":"24","dark":"","color":"topBar"}},[_c('v-icon',{staticClass:"icon-title"},[_vm._v("$executions")]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('executionRequests.checkers.title')))]),_c('v-spacer'),_c('v-btn',{staticClass:"info",attrs:{"icon":"","x-small":"","color":""},on:{"click":_vm.updateCheckers}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.loading ? 'fa-sync fa-spin' : 'fa-redo'))])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.checkers,"item-key":"id","hide-default-footer":"","items-per-page":_vm.checkers.length,"no-data-text":_vm.$t('dataTable.noDataText'),"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.exchangeId",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.exchangeId}})]}},{key:"item.market",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.currencySecondary) + "/" + (item.currencyPrimary)))+" ")]}},{key:"item.margin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.margin,null, 4))+" % ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,item.currencySecondary))+" ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.fee,item.feeCurrency))+" "+_vm._s(item.feeCurrency)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price,item.currencyPrimary))+" ")]}},{key:"item.priceRequested",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.priceRequested,item.currencyPrimary))+" ")]}},{key:"item.priceRequired",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.priceRequired,item.currencyPrimary))+" ")]}},{key:"item.hasOpportunity",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:{ 'black--text': item.hasOpportunity },attrs:{"x-small":"","label":"","color":item.hasOpportunity ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.hasOpportunity ? _vm.$t('executionRequests.checkers.found') : _vm.$t('executionRequests.checkers.notFound'))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }