import axios, { AxiosInstance } from 'axios';
import config from '@/config';
import store from '@/store';
export { captureException } from '@sentry/browser';

const getToken = (): string | false => {
  const session = store?.state?.Auth?.session;
  if (!session || !session.token || !session.expiresAt) return false;

  // if (session.expiresAt <= Date.now()) {
  //   store.dispatch('Auth/clearSession');
  //   window.location.reload();
  // }

  return session.token;
};

const gerSupervisorUserId = (): string | false => {
  const supervisorUser = store?.state?.Auth?.supervisorUser;
  if (!supervisorUser || !supervisorUser.id) return false;

  return supervisorUser.id;
};

const createAxios = (): AxiosInstance => {
  const http = axios.create({
    baseURL: config.apiUrl,
    headers: { 'Content-Type': 'application/json' },
  });

  http.interceptors.request.use(request => {
    request.headers['x-access-nonce'] = Date.now();
    const token = getToken();
    if (token) {
      request.headers.Authorization = token;
    }

    const supervisorUserId = gerSupervisorUserId();
    if (supervisorUserId) {
      request.headers['x-supervisor-user-id'] = supervisorUserId;
    }
    return request;
  });

  http.interceptors.response.use(
    response => response,
    async error => {
      if ([401, 403].includes(error?.response?.status) && error?.response?.config?.url !== '/api/v1/auth/login') {
        store.dispatch('Auth/clearSession');
        window.location.reload();
      }
      throw error;
    },
  );

  return http;
};

export default createAxios;
