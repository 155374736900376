






import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import beep from '@/helpers/beep';
const TradeStore = namespace('Trade');

@Component({})
export default class SoundControlBar extends Vue {
  @TradeStore.Action('toggleNotificationSound') toggleNotificationSound!: CallableFunction;
  @TradeStore.State('notificationSound') notificationSound!: boolean;
  @TradeStore.Action('toggleNotificationPending') toggleNotificationPending!: CallableFunction;
  @TradeStore.State('notificationPending') notificationPending!: boolean;

  get emitSound (): boolean {
    return this.notificationSound;
  }

  get iconColor (): string {
    if (this.emitSound) {
      return 'white';
    }
    return 'error';
  }

  get icon (): string {
    if (this.emitSound) {
      return 'fas fa-volume-up';
    }
    return 'fas fa-volume-mute';
  }

  @Watch('notificationPending')
  protected changeNotificationPending (pending: boolean) {
    if (this.notificationSound && pending) {
      beep();
      this.toggleNotificationPending();
    }
  }
}

