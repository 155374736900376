import { MutationTree } from 'vuex';
import { AuthState } from './types';

const mutations: MutationTree<AuthState> = {
  SET_USER (state, payload) {
    state.user = payload;
  },
  SET_SESSION (state, payload) {
    state.session = payload;
  },
  SET_USER_LANGUAGE (state, language) {
    if (state.user) {
      state.user.language = language;
    }
    state.currentUserLanguage = language;
  },
  SET_SIMULATION (state, payload) {
    state.simulation = payload;
  },

  SET_SUPERVISOR_USER (state, payload) {
    state.supervisorUser = payload;
  },
};

export default mutations;
