
import createExecutionRequestChecker, { ExecutionRequestCheckerInterface } from '@/entities/ExecutionRequestChecker';
import { createPagedMeta } from '@/entities/PagedMeta';
import PagedResource from '@/interfaces/PagedResource';
import http, { captureException } from '@/services/http';

export const getExecutionRequestCheckers = async (requestId: string): Promise<PagedResource<ExecutionRequestCheckerInterface>> => {
  try {
    const response = await http().get(`/api/v1/execution-requests/${requestId}/checkers`);
    if (!response?.data?.data || !Array.isArray(response?.data?.data)) return { data: [], meta: createPagedMeta() };
    return {
      data: response.data.data.map((e: any) => createExecutionRequestChecker(e)),
      meta: response?.data?.meta || createPagedMeta(),
    };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { data: [], meta: createPagedMeta() };
  }
};

export default getExecutionRequestCheckers;
