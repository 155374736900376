import createExchange, { ExchangeInterface } from '@/entities/Exchange';
import http, { captureException } from '@/services/http';
import FilteredOptionsInterface, { makeQueryParamsForRequest } from '@/entities/FilteredOptions';
import PagedResource from '@/interfaces/PagedResource';
import { createPagedMeta } from '@/entities/PagedMeta';

export const getExchanges = async (filteredOptions: FilteredOptionsInterface): Promise<PagedResource<ExchangeInterface>> => {
  try {
    const params = makeQueryParamsForRequest(filteredOptions);
    const response = await http().get('/api/v1/exchanges', { params });
    if (!response?.data?.data || !Array.isArray(response?.data?.data)) return { data: [], meta: createPagedMeta() };
    return {
      data: response.data.data.map((e: any) => createExchange(e)),
      meta: response?.data?.meta || createPagedMeta(),
    };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { data: [], meta: createPagedMeta() };
  }
};

export default getExchanges;
