import { OptionsPagedInterface, createOptionsPage } from './OptionsPaged';

export interface FilterInterface {
  field: string;
  operation?: string;
  value: any;
}

export interface FilteredOptionsInterface {
  filters: FilterInterface[];
  options: OptionsPagedInterface;
  populate?: string[],
  select?: string[],
}

export const makeQueryParamsForRequest = (filteredOptions?: FilteredOptionsInterface) => {
  const op = filteredOptions?.options || createOptionsPage();

  const params: any = {
    page: op.page,
    limit: op.limit,
    populate: filteredOptions?.populate || undefined,
  };

  if (op.sortBy && op.sortBy[0]) {
    params.orderBy = op.sortBy[0];
    params.orderByDirection = op.sortDesc[0] ? 'desc' : 'asc';
  }

  if (filteredOptions?.filters && filteredOptions?.filters.length > 0) {
    params.filters = filteredOptions.filters.map((f) => JSON.stringify(f));
  }

  if (filteredOptions?.select && filteredOptions?.select.length > 0) {
    params.select = filteredOptions?.select;
  }

  return params;
};

export default FilteredOptionsInterface;
