import _Vue from 'vue';
import Snotify, { SnotifyPosition, SnotifyDefaults, SnotifyToastConfig, SnotifyButton } from '@fireguard/vue-snotify';

export type NotifyButton = SnotifyButton;
export interface ConfirmButtonsOptions {
  vm: _Vue;
  yesText?: string;
  noText?: string;
  noCallback?: CallableFunction;
  yesCallBack: CallableFunction;
}
export const confirmButtons = (options: ConfirmButtonsOptions): NotifyButton[] => {
  return [
    {
      text: options.noText || String(options.vm.$t('notifications.noText')),
      action: (toast: any) => {
        options.vm.$notify.remove(toast.id);
        if (options.noCallback) {
          return options.noCallback();
        }
      },
    },
    {
      text: options.yesText || String(options.vm.$t('notifications.yesText')),
      bold: true,
      action: async (toast: any) => {
        options.vm.$notify.remove(toast.id);
        return await options.yesCallBack();
      },
    },
  ];
};

export type NotifyOptions = SnotifyToastConfig;

export interface NotifyInterface {
  success: (message: string, title?: string, options?: NotifyOptions) => void;
  error: (message: string, title?: string, options?: NotifyOptions) => void;
  warn: (message: string, title?: string, options?: NotifyOptions) => void;
  info: (message: string, title?: string, options?: NotifyOptions) => void;
  confirm: (message: string, title?: string, options?: NotifyOptions) => void;
  prompt: (message: string, title?: string, options?: NotifyOptions) => void;
  remove: (id: string | number) => void;
}

const NotifyPlugin = {
  install: (Vue: typeof _Vue) => {
    const options: SnotifyDefaults = {
      global: {
        maxOnScreen: 8,
        newOnTop: true,
        oneAtTime: false,
        preventDuplicates: false,
      },
      toast: {
        timeout: 4000,
        showProgressBar: true,
        position: SnotifyPosition.rightTop,
        pauseOnHover: true,
        titleMaxLength: 20,
        bodyMaxLength: 150,
      },
    };
    Vue.use(Snotify, options);

    Vue.prototype.$notify = {
      success: (message: string, title?: string, options: NotifyOptions = {}) => {
        Vue.prototype.$snotify.success(message, title, options);
      },
      error: (message: string, title?: string, options: NotifyOptions = {}) => {
        Vue.prototype.$snotify.error(message, title, options);
      },
      warn: (message: string, title?: string, options: NotifyOptions = {}) => {
        Vue.prototype.$snotify.warning(message, title, options);
      },
      info: (message: string, title?: string, options: NotifyOptions = {}) => {
        Vue.prototype.$snotify.info(message, title, options);
      },
      confirm: (message: string, title?: string, options?: NotifyOptions) => {
        const defaultButtons: SnotifyButton[] = [
          { text: 'Ok', bold: true },
        ];
        const config: NotifyOptions = Object.assign({
          ...{
            position: SnotifyPosition.centerCenter,
            bodyMaxLength: 1000,
            buttons: defaultButtons,
            backdrop: 0.8,
          },
          ...options || {},
        });
        Vue.prototype.$snotify.confirm(message, title, config);
      },
      prompt: (message: string, title?: string, options?: NotifyOptions) => {
        const config = Object.assign({
          ...{
            position: SnotifyPosition.centerCenter,
            bodyMaxLength: 1000,
            backdrop: 0.8,
          },
          ...options || {},
        });
        Vue.prototype.$snotify.prompt(message, title, config);
      },
      remove: (id: string | number) => {
        Vue.prototype.$snotify.remove(id);
      },
    } as NotifyInterface;
  },
};

export default NotifyPlugin;
