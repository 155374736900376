












import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Status extends Vue {
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: String, required: false }) label?: string;
  @Prop({ type: String, required: false }) description?: string;
  @Prop({ type: Boolean, required: false, default: false }) icon?: string;

  get statusIcon () {
    switch (this.name) {
      case 'OPEN': return '$statusOpen';
      case 'PENDING': return '$statusPending';
      case 'PROCESSING': return '$statusProcessing';
      case 'ERROR': return '$statusError';
      case 'PARTIAL': return '$statusPartial';
      case 'CANCELLED': return '$statusCancelled';
      case 'FINISHED': return '$statusFinished';
      case 'ENABLED': return '$statusEnabled';
      case 'DISABLED': return '$statusDisabled';
      default: return '$statusUnknown';
    }
  }

  get tooltip () {
    return this.description || '';
  }

  get statusColor () {
    switch (this.name) {
      case 'OPEN': return 'warning';
      case 'PENDING': return 'info';
      case 'PROCESSING': return 'indigo lighten-2';
      case 'ERROR': return 'error';
      case 'PARTIAL': return 'grey lighten-2';
      case 'CANCELLED': return 'orange';
      case 'FINISHED': return 'success';
      case 'ENABLED': return 'success';
      case 'DISABLED': return 'grey lighten-2';
      default: return 'grey';
    }
  }

  protected clickBtn () {
    this.$emit('click', { name: this.name, label: this.label || this.name });
  }
}
