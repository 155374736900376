































import { Vue, Component, Prop } from 'vue-property-decorator';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { FgResumeTextField },
  filters: { currency: currencyFilter },
})
export default class ResultForSettings extends Vue {
  @Prop({ type: Object, required: true }) result!: any;

  get headers () {
    return [
      {
        text: this.$t('reports.results.settings.headers.id').toString(),
        align: 'start',
        sortable: false,
        value: 'id',
        width: '80px',
      },
      {
        text: this.$t('reports.results.settings.headers.currency').toString(),
        align: 'start',
        sortable: false,
        value: 'currency',
        width: '70px',
      },
      {
        text: this.$t('reports.results.settings.headers.name').toString(),
        align: 'start',
        sortable: false,
        value: 'name',
        width: '20%',
      },
      {
        text: this.$t('reports.results.settings.headers.gain').toString(),
        align: 'end',
        sortable: false,
        value: 'gainValue',
        width: '20%',
      },
      {
        text: this.$t('reports.results.settings.headers.loss').toString(),
        align: 'end',
        sortable: false,
        value: 'lossValue',
        width: '20%',
      },
      {
        text: this.$t('reports.results.settings.headers.result').toString(),
        align: 'end',
        sortable: false,
        value: 'result',
        width: '25%',
      },
    ];
  }

  get settings () {
    const list: any = [];
    for (const settingsId of Object.keys(this.result)) {
      for (const currency of Object.keys(this.result[settingsId])) {
        const current = this.result[settingsId][currency];
        list.push({
          ...current,
          id: settingsId,
        });
      }
    }
    return list;
  }
}

