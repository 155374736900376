export const isInternal = (user: { roles?: string[] }) => {
  if (!user?.roles) {
    return false;
  }
  return user.roles.includes('admin') || user.roles.includes('operator');
};

export const isAdmin = (user: { roles?: string[] }) => {
  if (!user?.roles) {
    return false;
  }
  return user.roles.includes('admin');
};

export const enabledFeature = (user: { features?: string[] }, feature: string) => {
  if (!user?.features) {
    return false;
  }
  return user.features.includes(feature);
};

export default { isInternal, isAdmin, enabledFeature };
