import createExecutionRequest, { ExecutionRequestInterface, NewExecutionRequestInterface } from '@/entities/ExecutionRequest';
import http, { captureException } from '@/services/http';

export const saveExecutionRequest = async (
  request: NewExecutionRequestInterface,
): Promise<{success: boolean, data?: ExecutionRequestInterface, message?: string}> => {
  try {
    const response = await http().post('/api/v1/execution-requests', request);
    return { success: true, data: createExecutionRequest(response.data?.data || {}) };
  } catch (e) {
    captureException(e);
    console.error(e);

    return { success: false, message: e.response?.data?.message || e.message };
  }
};

export default saveExecutionRequest;
