




























import { Vue, Component, Prop } from 'vue-property-decorator';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import { currencyFilter } from '@/filters/currencyFilter';

@Component({
  components: { FgResumeTextField },
  filters: { currency: currencyFilter },
})
export default class ResultForSettings extends Vue {
  @Prop({ type: Object, required: true }) result!: any;

  get headers () {
    return [
      {
        text: this.$t('reports.results.currency.headers.currency').toString(),
        align: 'start',
        sortable: false,
        value: 'currency',
        width: '10%',
      },
      {
        text: this.$t('reports.results.currency.headers.gain').toString(),
        align: 'end',
        sortable: false,
        value: 'gainValue',
        width: '30%',
      },
      {
        text: this.$t('reports.results.currency.headers.loss').toString(),
        align: 'end',
        sortable: false,
        value: 'lossValue',
        width: '30%',
      },
      {
        text: this.$t('reports.results.currency.headers.result').toString(),
        align: 'end',
        sortable: false,
        value: 'result',
        width: '30%',
      },
    ];
  }

  get currentResult () {
    const list: any = [];
    for (const currency of Object.keys(this.result)) {
      const current = this.result[currency];
      list.push({
        ...current,
        currency,
      });
    }
    return list;
  }
}

