











































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionDetails from '@/components/Executions/ExecutionDetails.vue';
import { timeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { ExecutionInterface } from '@/entities/Execution';
import { OrderInterface } from '@/entities/Order';

const TradeStore = namespace('Trade');

@Component({
  components: { FgWidget, FgStatus, FgExecutionDetails },
  filters: { time: timeFilter, currency: currencyFilter },
})
export default class ExecutionsStream extends Vue {
  @TradeStore.State('executions') executions?: any;
  @TradeStore.Action('updateLastExecutions') updateLastExecutions!: CallableFunction;

  protected headers: any = [];
  protected loading = false;
  protected showExecutionDetails = false;
  protected currentExecution?: ExecutionInterface;

  get isMobile () {
    return this.$vuetify.breakpoint.xs;
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('executions.headers.status').toString(),
        align: 'center',
        sortable: false,
        value: 'status',
        width: '60px',
      },
      {
        text: this.$t('executions.headers.createdAt').toString(),
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
      {
        text: this.$t('executions.headers.strategy').toString(),
        align: 'center',
        sortable: false,
        value: 'strategy',
      },
      {
        text: this.$t('executions.headers.amount').toString(),
        align: 'start',
        sortable: false,
        value: 'amount',
      },
      {
        text: this.$t('executions.headers.buy').toString(),
        align: 'start',
        sortable: false,
        value: 'buy',
      },
      {
        text: this.$t('executions.headers.sell').toString(),
        align: 'start',
        sortable: false,
        value: 'sell',
      },
      {
        text: this.$t('executions.headers.executedResult').toString(),
        align: 'start',
        sortable: false,
        value: 'executedResult',
      },
    ];
  }

  protected async updateExecutionList () {
    this.loading = true;
    try {
      await this.updateLastExecutions();
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  protected async mounted () {
    await this.updateExecutionList();
  }

  protected getColorByItem (item: ExecutionInterface) {
    if (item.executedResult > 0) return 'success';
    if (item.executedResult < 0) return 'error';
    return 'grey';
  }

  protected getIconByItem (item: ExecutionInterface) {
    if (item.executedResult > 0) return '$up';
    if (item.executedResult < 0) return '$down';
    return 'fas fa-times';
  }

  protected getBuyOrders (orders: OrderInterface[]) {
    return orders?.filter(o => o.operation === 'BUY') || [];
  }

  protected getSellOrders (orders: OrderInterface[]) {
    return orders?.filter(o => o.operation === 'SELL') || [];
  }

  protected toggleExecutionDetails () {
    this.showExecutionDetails = !this.showExecutionDetails;
  }

  protected rowClicked (_: any, row: any) {
    this.currentExecution = row.item;
    this.toggleExecutionDetails();
  }
}

