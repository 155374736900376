var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-for-settings-component"},[_c('h3',[_vm._v(_vm._s(_vm.$t('reports.results.settings.title')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.settings,"dense":"","item-key":"id","hide-default-footer":"","items-per-page":10000,"no-data-text":_vm.$t('dataTable.noDataText')},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.gainValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.gainCount)+"]")]),_vm._v(_vm._s(_vm._f("currency")(item.gainValue,item.currency))+" ")]}},{key:"item.lossValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.lossCount)+"]")]),_vm._v(_vm._s(_vm._f("currency")(item.lossValue,item.currency))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.gainCount + item.lossCount)+"]")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.result,item.currency))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }