





























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgSoundControl from './SoundControl.vue';
import config from '@/config';

const DashboardStore = namespace('Dashboard');
const AuthStore = namespace('Auth');

@Component({ components: { FgSoundControl } })
export default class AppToolBar extends Vue {
  @DashboardStore.Action('toogleShowSidebar') toogleShowSidebar!: CallableFunction;
  @DashboardStore.Getter('appVersion') appVersion!: CallableFunction;
  @AuthStore.Action('changeSupervisorUser') changeSupervisorUser!: CallableFunction;
  @AuthStore.Getter('isSimulation') isSimulation!: boolean;
  @AuthStore.Getter('isAdmin') isAdmin!: boolean;
  @AuthStore.State('supervisorUser') supervisorUser?: string;

  get isMobile (): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  get height (): number {
    return this.isMobile ? 60 : 40;
  }

  get appName (): string {
    return config?.name || 'CriptoBot';
  }

  protected async clearSupervisorUser () {
    console.log('clearSupervisorUser');
    await this.changeSupervisorUser(null);
  }

  protected handlerSupervisorClick () {
    this.$emit('click', 'supervisor');
  }
}

