










































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import FgEventLogsListFilters from '@/components/EventLogs/EventLogsListFilters.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import PagedResource from '../../interfaces/PagedResource';
import FilteredOptionsInterface, { FilterInterface } from '../../entities/FilteredOptions';
import { createPagedMeta } from '../../entities/PagedMeta';
import { createOptionsPage } from '../../entities/OptionsPaged';
import getEventLogs from '../../services/EventLogs/getEventLogs';
import { EventLogInterface } from '../../entities/EventLog';

const AuthStore = namespace('Auth');

@Component({
  components: { FgWidget, FgEventLogsListFilters, FgResumeTextField },
  filters: { dateTime: dateTimeFilter },
})
export default class EventLogsList extends Vue {
  @AuthStore.Getter('isInternal') isInternal?: boolean;

  protected resource: PagedResource<EventLogInterface> = { data: [], meta: createPagedMeta() };
  protected options = createOptionsPage(this.resource?.meta);
  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  protected headers: any[] = [];
  protected loading = false;
  protected showFormOptions = false;
  protected currentSettings: any = {}

  get logs () {
    return this?.resource?.data || [];
  }

  get totalEventLogs () {
    return this.resource?.meta?.total || this.resource?.data?.length || 0;
  }

  get showFooter () {
    return !!this.resource?.meta;
  }

  get footerProps () {
    return {
      itemsPerPageOptions: [5, 10, 30, 100],
      showFirstLastPage: true,
      itemsPerPageText: this.$t('dataTable.rowsPerPage'),
    };
  }

  protected beforeMount () {
    this.headers = [
      {
        text: this.$t('eventLogs.headers.createdAt').toString(),
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: this.$t('eventLogs.headers.id').toString(),
        align: 'start',
        sortable: true,
        value: 'id',
        width: '80px',
        filterable: true,
      },

      {
        text: this.$t('eventLogs.headers.level').toString(),
        align: 'start',
        sortable: true,
        value: 'level',
        filterable: true,
        operation: 'ilike',
      },

      {
        text: this.$t('eventLogs.headers.origin').toString(),
        align: 'start',
        sortable: true,
        value: 'origin',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('eventLogs.headers.event').toString(),
        align: 'start',
        sortable: false,
        value: 'event',
        filterable: true,
        operation: 'ilike',
      },
      {
        text: this.$t('eventLogs.headers.message').toString(),
        align: 'start',
        sortable: false,
        value: 'message',
      },
    ];

    if (this.isInternal) {
      this.headers.splice(2, 0, {
        text: this.$t('eventLogs.headers.userId').toString(),
        align: 'start',
        sortable: true,
        value: 'userId',
        width: '80px',
        filterable: true,
      });
    }
  }

  protected async updateDatagrid () {
    try {
      this.loading = true;
      this.resource = await getEventLogs({
        ...this.filteredOptions,
      });

      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  public async mounted () {
    this.options = createOptionsPage(this.resource?.meta);
    await this.updateDatagrid();
  }

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  @Watch('options')
  protected async changeOptions (value: any) {
    value.limit = value.itemsPerPage;
    this.filteredOptions.options = value;
    await this.updateDatagrid();
  }
}

