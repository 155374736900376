import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        black: '#000000',
        white: '#ffffff',
        background: '#ffffff',
      },
      dark: {
        primary: colors.blue.darken2,
        secondary: colors.amber.darken3,
        accent: colors.grey.lighten3,
        error: colors.deepOrange.accent4,
        info: colors.teal.lighten1,
        success: colors.green.accent3,
        warning: colors.amber.base,
        black: '#000000',
        white: '#ffffff',
        background: '#121212',
      },
    },
  },
  icons: {
    iconfont: 'fa',
    values: {
      dashboard: 'fa fa-th-large',
      settings: 'fas fa-cogs',
      exchanges: 'fas fa-swatchbook',
      user: 'fas fa-user-shield',
      users: 'fas fa-users',
      // menu: 'fas fa-ellipsis-v',
      bot: 'fas fa-robot',
      person: 'fas fa-user-shield',
      lock: 'fas fa-lock',
      unlock: 'fas fa-lock-open',
      show: 'fas fa-eye-slash',
      hide: 'fas fa-eye',
      login: 'fas fa-sign-in-alt',
      logout: 'fas fa-sign-out-alt',
      profile: 'fas fa-id-card',
      zero: 'fas fa-sort',
      up: 'fas fa-caret-up',
      down: 'fas fa-caret-down',
      config: 'fas fa-cog',
      wallet: 'fas fa-wallet',
      alert: 'fas fa-exclamation-triangle',
      save: 'fas fa-save',
      execute: 'fas fa-bolt',
      run: 'fas fa-play',
      print: 'fas fa-print',
      retry: 'fas fa-undo-alt',

      events: 'fas fa-stream',
      eventLogs: 'far fa-list-alt',
      executions: 'fas fa-file-invoice-dollar',
      executionRequests: 'fas fa-receipt',
      reports: 'fas fa-chart-line',
      chart: 'fas fa-chart-line',
      history: 'fas fa-history',
      filters: 'fas fa-filter',
      calendar: 'far fa-calendar-alt',

      statusOpen: 'fas fa-star',
      statusPending: 'fas fa-spinner',
      statusProcessing: 'far fa-clock',
      statusError: 'fas fa-bug',
      statusCancelled: 'fa fa-ban',
      statusFinished: 'fa fa-check-double',
      statusPartial: 'fas fa-unlink',
      statusUnknown: 'fas fa-question',
      statusEnabled: 'fas fa-toggle-on',
      statusDisabled: 'fas fa-toggle-off',
    },
  },
});
