import { GetterTree } from 'vuex';
import { DashboardState } from './types';

const getters: GetterTree<DashboardState, DashboardState> = {
  appVersion (state) {
    return state?.app?.version || '0';
  },
  isSimulation (state) {
    return !!state?.app?.simulation;
  },
};

export default getters;
