import http, { captureException } from '@/services/http';

export interface ChangePasswordOptions {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const changePassword = async (data: ChangePasswordOptions): Promise<{ status: 'success'|'error', message?: string, translate?: boolean}> => {
  try {
    if (!data.newPassword || data.newPassword.length < 6) {
      return { status: 'error', message: 'newPassword', translate: true };
    }

    if (!data.currentPassword) {
      return { status: 'error', message: 'currentPassword', translate: true };
    }

    if (!data.confirmNewPassword || data.confirmNewPassword !== data.newPassword) {
      return { status: 'error', message: 'confirmNewPassword', translate: true };
    }
    await http().patch('/api/v1/users/change-password', data);
    return { status: 'success', message: '' };
  } catch (e) {
    captureException(e);
    console.error(e);
    return { status: 'error', message: e.response?.data?.message || e.message, translate: false };
  }
};

export default changePassword;
