import { TradeState } from './types';

export const getState = (): TradeState => ({
  settings: [],
  exchanges: [],
  balances: [],
  opportunities: [],
  executions: [],
  executionRequests: [],
  tickers: {},
  bestTickers: {},
  events: [],
  registerEvents: true,
  statistics: {
    currency: undefined,
    executions: {},
  },
  exchangeDrivers: [],
  notificationPending: false,
  notificationSound: true,
});

export default Object.assign(getState(), { getState });
