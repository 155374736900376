var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"volume-for-exchange-component"},[_c('h3',[_vm._v(_vm._s(_vm.$t('reports.results.exchange.title')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.exchanges,"dense":"","item-key":"key","hide-default-footer":"","items-per-page":10000,"no-data-text":_vm.$t('dataTable.noDataText'),"group-by":"name","group-desc":true,"show-expand":false},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{staticStyle:{"background-color":"var(--v-background-lighten1)","height":"20px"},attrs:{"colspan":_vm.headers.length}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(items[0].name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(items[0].id))])])],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.partialValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.partialCount)+"]")]),_vm._v(_vm._s(_vm._f("currency")(item.partialValue,item.currency))+" ")]}},{key:"item.finishedValue",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.finishedCount)+"]")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.finishedValue,item.currency))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption pr-1 info--text"},[_vm._v("["+_vm._s(item.count)+"]")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.value,item.currency))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }