





















import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgStatus from '@/components/Dashboard/Status.vue';
import FgWidget from '@/components/Dashboard/Widget.vue';
import FgExecutionsDataTable from '@/components/Executions/ExecutionsDataTable.vue';
import FgExecutionDetails from '@/components/Executions/ExecutionDetails.vue';
import { dateTimeFilter } from '@/filters/dateFilter';
import { currencyFilter } from '@/filters/currencyFilter';
import { ExecutionInterface } from '@/entities/Execution';
import getExecutions from '@/services/Executions/getExecutions';
import { createOptionsPage } from '@/entities/OptionsPaged';
import FilteredOptionsInterface, { FilterInterface } from '@/entities/FilteredOptions';
import FgResumeTextField from '@/components/Core/ResumeTextField.vue';
import PagedResource from '@/interfaces/PagedResource';
import { createPagedMeta } from '@/entities/PagedMeta';

const TradeStore = namespace('Trade');

@Component({
  components: { FgWidget, FgStatus, FgExecutionsDataTable, FgExecutionDetails, FgResumeTextField },
  filters: { dateTime: dateTimeFilter, currency: currencyFilter },
})
export default class ExecutionsList extends Vue {
  protected loading = false;

  protected resource: PagedResource<ExecutionInterface> = { data: [], meta: createPagedMeta() };
  protected showExecutionDetails = false;
  protected currentExecution?: ExecutionInterface;
  protected options = createOptionsPage(this.resource?.meta);

  protected filteredOptions: FilteredOptionsInterface = {
    filters: [],
    options: this.options,
  }

  @TradeStore.Getter('lastExecution') lastExecution!: string;

  protected async changeFilters (filters: FilterInterface[]) {
    this.filteredOptions.filters = filters;
    await this.updateDatagrid();
  }

  protected async mounted () {
    await this.updateDatagrid();
  }

  protected async updateDatagrid () {
    this.loading = true;
    try {
      this.resource = await getExecutions({
        ...this.filteredOptions,
        populate: ['orders', 'orders.exchange'],
      });
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  protected toggleExecutionDetails () {
    this.showExecutionDetails = !this.showExecutionDetails;
  }

  protected rowClicked (row: any) {
    this.currentExecution = row.item;
    this.toggleExecutionDetails();
  }

  protected async changeOptions (value: any) {
    value.limit = value.itemsPerPage;
    this.filteredOptions.options = value;
    await this.updateDatagrid();
  }

  @Watch('lastExecution')
  protected async updatedExecutionList () {
    if (this.resource?.meta?.page === 1) {
      await this.updateDatagrid();
    }
  }
}

