var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showFilters)?_c('fg-executions-filters',{attrs:{"resource":_vm.resource,"headers":_vm.headers,"loading":_vm.loading,"filters":_vm.filters},on:{"change":_vm.changeFilters}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 executions-data-table-component",attrs:{"dense":"","headers":_vm.headers,"items":_vm.executions,"item-key":"id","no-data-text":_vm.$t('dataTable.noDataText'),"options":_vm.options,"server-items-length":_vm.totalExecutions,"loading":_vm.loading,"footer-props":_vm.footerProps,"hide-default-footer":!_vm.showFooter},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.id}})]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('fg-resume-text-field',{attrs:{"text":item.userId}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('fg-status',{attrs:{"name":item.status,"description":_vm.$t(("status." + (item.status))),"icon":!_vm.isMobile}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.orders[0] ? item.orders[0].amount : 0,item.orders[0] ? item.orders[0].currencySecondary : ''))+" "+_vm._s(item.orders[0] ? item.orders[0].currencySecondary : '')+" ")]}},{key:"item.buy",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getBuyOrders(item.orders)),function(o){return _c('div',{key:o.id},[_c('fg-status',{attrs:{"name":o.status,"description":o.description ? o.description : _vm.$t(("status." + (o.status))),"icon":true}}),_vm._v(" "+_vm._s(o.exchange ? o.exchange.name : '?')+" "),_c('span',{staticClass:"caption"},[_vm._v("V:"+_vm._s(_vm._f("currency")(o.value,o.currencyPrimary))+" P:"+_vm._s(_vm._f("currency")(o.price,o.currencyPrimary)))])],1)})}},{key:"item.sell",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getSellOrders(item.orders)),function(o){return _c('div',{key:o.id},[_c('fg-status',{attrs:{"name":o.status,"description":o.description ? o.description : _vm.$t(("status." + (o.status))),"icon":true}}),_vm._v(" "+_vm._s(o.exchange ? o.exchange.name : '?')+" "),_c('span',{staticClass:"caption"},[_vm._v("V:"+_vm._s(_vm._f("currency")(o.value,o.currencyPrimary))+" P:"+_vm._s(_vm._f("currency")(o.price,o.currencyPrimary)))])],1)})}},{key:"item.executedResult",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"x-small":item.executedResult === 0,"color":_vm.getColorByItem(item)}},[_vm._v(_vm._s(_vm.getIconByItem(item))+" ")]),_vm._v(" "+_vm._s(_vm._f("currency")(item.executedResult,item.executedResultCurrency))+" "+_vm._s(item.executedResultCurrency)+" "),(_vm.showResultPercent(item))?_c('span',{staticStyle:{"font-size":"0.8em"}},[_vm._v(" ("+_vm._s(_vm._f("currency")(_vm.getExecutedResultPercent(item),null, 2))+"%) ")]):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }