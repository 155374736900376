























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FgWidget from '@/components/Dashboard/Widget.vue';
import saveExchange from '@/services/Exchanges/saveExchange';
import createExchange, { ExchangeInterface } from '@/entities/Exchange';
import { dateTimeFilter } from '@/filters/dateFilter';

const TradeStore = namespace('Trade');

@Component({
  components: { FgWidget },
  filters: { dateTime: dateTimeFilter },
})
export default class ExchangeForm extends Vue {
  @Prop({ type: Object, required: true }) readonly exchange!: ExchangeInterface;
  @TradeStore.State('exchangeDrivers') exchangeDrivers?: ExchangeInterface[];
  @TradeStore.Action('updateExchangeDrivers') updateExchangeDrivers!: CallableFunction;

  protected mutableExchange: any = {};
  protected exchangeEnabled = false;
  protected valueTest = '';
  protected loading = false;
  protected exchangeDriver: any = {};
  protected showAditionalData = 0;
  protected isValid = false;

  protected exchangeDocumentType = ['CPF', 'CNPJ', 'NIF_PF', 'NIF_PJ', 'PASSPORT', 'COUNTRY_NO_ID', 'USER_NO_ID'];

  get isNew () {
    return !this.mutableExchange.id;
  }

  get isMobile () {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get requiredRules () {
    return [
      (value: string) => !!value || 'Required.',
    ];
  }

  get exchangeRules () {
    return [
      (value: string) => (!this.isNew || !!value) || 'Required.',
    ];
  }

  get nameRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => (value && value.length >= 3) || 'Min 3 characters',
    ];
  }

  get keyRules () {
    return [
      (value: string) => !!value || 'Required.',
      (value: string) => (value && value.length >= 3) || 'Min 3 characters',
    ];
  }

  get countryRules () {
    return [
      (value: string) => (value === '' || (value && value.length === 2)) || 'Require country with 2 characters',
    ];
  }

  get feeRules () {
    return [
      (value: string) => value !== '' || 'Required.',
      (value: string) => {
        if (!/^-?(\d*\.)?\d+$/.test(value)) return 'Invalid number format';

        const number = parseFloat(value);
        if (!isNaN(number) && number >= -100 && number <= 100) return true;

        return 'Requires a valid number between -100 and 100';
      },
    ];
  }

  protected close (params = {}) {
    this.$emit('close', params);
  }

  protected beforeMount () {
    this.mutableExchange = createExchange(this.exchange || {});
    this.exchangeEnabled = this.mutableExchange.status === 'ENABLED';
    if (this.mutableExchange.driver && this.mutableExchange.driverExchangeId) {
      this.exchangeDriver = `${this.mutableExchange.driver}:${this.mutableExchange.driverExchangeId}`;
    }
  }

  protected async mounted () {
    if (this.exchangeDrivers?.length === 0) {
      await this.updateExchangeDrivers();
    }
  }

  protected validate () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.form.validate();
  }

  protected async save () {
    this.validate();
    if (!this.isValid) return false;

    this.mutableExchange.status = this.exchangeEnabled ? 'ENABLED' : 'DISABLED';
    const updated = this.updateMutableExchange();
    if (!updated) {
      this.$notify.error('Invalid exchange driver', this.$t('actions.error'));
      return;
    }

    const savedExchange = await saveExchange(this.mutableExchange);
    if (savedExchange) {
      this.$notify.success('Save with success', this.$t('actions.success'));
      return this.close({ refresh: true });
    }

    this.$notify.error('Error on save', this.$t('actions.error'));
  }

  protected updateMutableExchange () {
    const exchange = (this.exchangeDrivers || []).find((e) => e.id === this.exchangeDriver);
    if (!exchange) {
      return false;
    }
    this.mutableExchange.driver = exchange.driver;
    this.mutableExchange.driverExchangeId = exchange.driverExchangeId;
    this.mutableExchange.apiUrl = exchange.apiUrl || '';

    return true;
  }
}

